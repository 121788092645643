import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import Steps from './steps/Steps';


export default function Welcome(props) {


  return (
    <Box 
      sx={{
        width: '100%',
        padding: '40px 80px',
        overflowY: 'auto',
      }}
    >
      <h1>Welcome to your digital workshop.</h1>
      <p>Explore, customize, combine, and display digital art of the isms NFT collection.</p>
      <p>Who said NFTs are just JPEGs?</p>
      <Steps />
    </Box>
  );
}
