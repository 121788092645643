import * as React from 'react';

import { useLocation, useNavigate, NavLink } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import logo from '../../../../static/images/logos/isms_logo_png.png';


export default function MobileNav(props) {
  const location = useLocation();
  console.log(location.pathname);

  let navigate = useNavigate();


  let color = '#d4d4d4';
  // let color = colors[Math.floor(Math.random() * colors.length)];
  const links = [
    {
      id: 'home',
      name: 'Home',
      to: '/',
    },
    {
      id: 'mint',
      name: 'Mint',
      to: '/mint',
    },
    {
      id: 'about',
      name: 'About',
      to: '/about',
    },
    {
      id: 'gallery',
      name: 'Gallery',
      to: '/gallery',
    },
    {
      id: 'lab',
      name: 'Lab',
      to: '/lab',
    },
  ];

  const handleDirect = (to) => {
    navigate(to);
  }

  return (
    <Box sx={{
      flexGrow: 1,
      mb:1,
    }}>
      <AppBar position="static" color='default'>
        <Toolbar sx={{
          display: 'flex',
          justifyContent: 'space-between',
          py: 1,
        }}>
          <div>
            <img src={logo} width={33} height={33} />
            <Typography
              variant='h3'
              color='primary'
              style={({ isActive }) => ({
                color: isActive ? '#545e6f' : color,
                fontFamily: 'Spartan',
                textDecoration: 'none',
              })}
              sx={{ml:2}}
              component={NavLink}
              to='/'
            >
              isms
            </Typography>
          </div>
          
          <FormControl variant='standard' sx={{
            width: 160,
          }}>
            <Select
              id="mobile-main-nav"
              value={location.pathname}
              onChange={(e) => handleDirect(e.target.value)}
              sx={{
                color: '#545e6f',
                fontSize: 24,
              }}
            >
              {
                links.map((link, li) => (
                  <MenuItem value={link.to}>
                    { link.name }
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Toolbar>
      </AppBar>
    </Box>

  );
}
