import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";

import ReactMarkdown from 'react-markdown';

import Box from '@mui/material/Box';

import introDoc from '../../../../docs/lab/intro.md';
import gridDoc from '../../../../docs/lab/settings/grid.md';
import itemsDoc from '../../../../docs/lab/settings/items.md';

import InfoMenu from './layout/InfoMenu';
import InfoMenuMobile from './layout/InfoMenuMobile';
import Welcome from './welcome/Welcome';
import GettingStarted from './getting-started/GettingStarted';

export default function Info(props) {

  const [file, setFile] = useState();
  const [intro, setIntro] = useState();
  const [grid, setGrid] = useState();
  const [items, setItems] = useState();

  useEffect(() => {
    if (props.settings && props.step === 0){
      fetch(gridDoc).then((response) => response.text()).then((text) => {
        setGrid(text);
      });
    } else if (props.settings && props.step === 1){
      fetch(itemsDoc).then((response) => response.text()).then((text) => {
        setItems(text);
      });
    } else if (!props.settings){
      fetch(introDoc).then((response) => response.text()).then((text) => {
        setIntro(text);
      });
    }
  }, [props.settings, props.step]);

  return (
    <Box 
      sx={{
        width: '100%',
        height: '100vh',
        padding: '40px 80px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      {
        props.width > 730 ? (
          <InfoMenu />
        ) : (
          <InfoMenuMobile />
        )
      }
      <Box 
        sx={{
          flexGrow: 1,
          p: 2,
          width: '65%',
          overflowY: 'auto',
        }}
      >
        <Routes>
          <Route 
            path='*'  
            element={
              <Welcome />
            } 
          />
          <Route 
            path="/getting-started" 
            element={<GettingStarted />} 
          />
          <Route 
            path="/grid" 
            element={
              <ReactMarkdown>
                { grid }
              </ReactMarkdown>
            } 
          />
          <Route 
            path="/items" 
            element={
              <ReactMarkdown>
                { items }
              </ReactMarkdown>
            } 
          />
        </Routes>
      </Box>
    </Box>
  );
}
