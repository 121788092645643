import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

export default function GettingStarted(props) {


  return (
    <Box 
      sx={{
        width: '100%',
        padding: '40px 80px',
        overflowY: 'auto',
      }}
    >
      <h1>Getting Started</h1>
      <p>Explore, customize, combine, and display digital art of the isms NFT collection.</p>
    </Box>
  );
}
