import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


import Appearance from '../../../../lab/controller/settings/sections/items/item-config/appearance/Appearance';

export default function Controls(props) {


  return (
    <Box
      sx={{
        flexGrow: 1,
        width: '50%',
        pr: 4,
      }}
    >
      <Button 
        variant='contained'
        sx={{width:'200px', mb:2}}
        onClick={props.setTraits}
      >
        Randomize
      </Button>
      <Appearance 
        item={props.item}
        invert={props.item.invert}
        order={1}
        updateItem={props.updateItem}
      />

    </Box>
  );
}
