import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';

import WordCarousel from '../../../layout/word-carousel/WordCarousel';

const fontsList = require('../../../../../static/fonts/fonts_list.json');



export default function Fonts(props) {

  const [fonts, setFonts] = useState([]);

  useEffect(() => {
    let holder = [];
    fontsList.map((f, fi) => {
      console.log('f', f);
      holder.push({
        text: f.name,
        font: f.name
      });
    });
    console.log('holder', holder);
    setFonts(holder);
  }, []);


  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mb: '20px',
    }}>
      <h3 style={{width:'100%',textAlign:'center'}}>60 Fonts</h3>
      {
        fonts.length > 0 && (
          <WordCarousel items={fonts} width={200} />
        )
      }
    </Box>
  );
}
