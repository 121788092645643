import React from 'react';

import _, { map } from 'underscore';

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';


const GalleryTile = styled(Box, { shouldForwardProp: (prop) => prop !== ('width' || 'textColor' || 'backgroundColor' || 'fontFamily'  || 'factor'|| 'fontWeight') })(
  ({ theme, width, textColor, backgroundColor, fontFamily, factor, fontWeight }) => ({
    display: 'flex',
    margin: 2,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: width,
    height: width,
    paddingLeft: width * 0.05,
    paddingRight: width * 0.05,
    fontSize: width * (126/1000),
    fontWeight: fontWeight,
    wordBreak: 'normal',
    lineHeight: `${width * (132/1000)}px`,
    color: textColor,
    backgroundColor: backgroundColor,
    fontFamily: fontFamily,
    whiteSpace: 'pre-line',
    "&:hover": {
      backgroundColor: textColor,
      color: backgroundColor,
      cursor: 'pointer',
    },
  }),
);

export default function GalleryGrid(props) {
  const width = window.innerWidth < 600 ? (window.innerWidth-40)/2 : 296;

  let imgArr = Object.entries(props.images);

  return (
    <Box
      sx={{
        width: '100%',
        pb: '40px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {
        props.images.map((item, iid) => (
          <GalleryTile
            key={`gallery-item-${iid}`}
            width={width}
            textColor={item.textColor}
            backgroundColor={item.backgroundColor}
            fontFamily={item.font}
            fontWeight={item.weight}
            factor={item.size}
            iid={iid}
          >
            <p>{ item.phrase }</p>
          </GalleryTile>
        ))
      }
    </Box>
  );
}
