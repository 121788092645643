import React from 'react';

import Box from '@mui/material/Box';

import WordCarousel from '../../../layout/word-carousel/WordCarousel';

const collections = [
  {
    text: 'Absurdism',
    font: '',
    link: '',
  },
  {
    text: 'Altrusism',
    font: '',
    link: '',
  },
  {
    text: 'Collectivism',
    font: '',
    link: '',
  },
  {
    text: 'Cynicism',
    font: '',
    link: '',
  },
  {
    text: 'Existentialism',
    font: '',
    link: '',
  },
  {
    text: 'Individualism',
    font: '',
    link: '',
  },
  {
    text: 'Materialism',
    font: '',
    link: '',
  },
  {
    text: 'Minimalism',
    font: '',
    link: '',
  },
  {
    text: 'Modernism',
    font: '',
    link: '',
  },
  {
    text: 'Narcissism',
    font: '',
    link: '',
  },
  {
    text: 'Nihilism',
    font: '',
    link: '',
  },
  {
    text: 'Optimism',
    font: '',
    link: '',
  },
  {
    text: 'Pessimism',
    font: '',
    link: '',
  },
  {
    text: 'Romanticism',
    font: '',
    link: '',
  },
  {
    text: 'Stoicism',
    font: '',
    link: '',
  },
];

export default function Collections(props) {


  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mb: '20px',
    }}>
      <h3 style={{width:'100%',textAlign:'center'}}>15 Collections</h3>
      <WordCarousel items={collections} />
    </Box>
  );
}
