import React from 'react';
import { styled } from '@mui/system';
import { keyframes } from '@mui/system';

const gradient = keyframes`
 0% {
   background-position: 0% 50%;
 }
 50% {
   background-position: 100% 50%;
 }
 100% {
   background-position: 0% 50%;
 }
}`;


export default function DisplayBackground(props) {

  var colorString = ``;
  props.displayColors.colors.map(color => {
    colorString = colorString.concat( `, `, color);
  });

  const StyledBackground = styled('div', {})({
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: props.displayColors.dynamic == false ? props.displayColors.colors[0] : `linear-gradient(${props.displayColors.angle} ${colorString})`,
    backgroundSize: `400% 400%`,
	  animation: props.displayColors.dynamic == false ? '' : `${gradient} ${props.displayColors.duration} ease infinite`,
  })

  return (
    <StyledBackground>
      { props.children }
    </StyledBackground>
  );
}
