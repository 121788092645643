import React, { useState } from 'react';

import Box from '@mui/material/Box';

import ToggleGroup from '../../../../layout/inputs/ToggleGroup';
import Static from './Static';
import Animation from './Animation';
import HighlightConfig from './HighlightConfig';


export default function Appearance(props) {
  const [invert, setInvert] = useState(props.invert);
  const [t, setT] = useState(props.item.text);
  const [bg, setBg] = useState(props.item.background);
  const [highlight, setHighlight] = useState(props.item.highlight);

  const invertOptions = [
    {
      name: 'standard',
      value: false,
    },
    {
      name: 'inverted',
      value: true,
    },
  ];

  const highlightOptions = [
    {
      name: 'on',
      value: true,
    },
    {
      name: 'off',
      value: false,
    },
  ];

  const updateValue = (type, val) => {
    props.updateItem(props.order, type, val);
  };


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <Static item={props.item} updateValue={updateValue} />
      <Animation item={props.item} updateValue={updateValue} />
    

    </Box>
  );
}
