import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';

import GalleryNav from './nav/GalleryNav';
import GalleryGrid from './GalleryGrid';



export default function Gallery(props) {
  let { type } = useParams();
  const initialFilters = {
    set: type !== ('' || undefined) ? type : 'absurdism',
    palette: 'pastel',
    font: '',
    textColor: '',
    backgroundColor: '',
    phrase: ''
  };

  const [images, setImages] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(initialFilters);

  const updateFilter = (field, newVal) => {
    let newFilter = {...filter};
    newFilter[field] = newVal;
    setFilter(newFilter);
  }

  const clearFilter = () => {
    console.log('cleared');
    setFilter(initialFilters);
  };

  let navigate = useNavigate();
  function handleClick() {
    navigate("/");
  }


  useEffect(() => {
    var items = props.ismsData.filter(item => item.palette == filter.palette && item.set == filter.set);

    if(filter.font !== ''){
      items = items.filter(item => item.font == filter.font);
    }
    if(filter.textColor !== ''){
      items = items.filter(item => item.textColor == filter.textColor);
    }
    if(filter.backgroundColor !== ''){
      items = items.filter(item => item.backgroundColor == filter.backgroundColor);
    }
    setImages(items);
    console.log('items', items);
  }, [filter]);

  useEffect(() => {
    var items = props.ismsData.filter(item => item.palette == filter.palette && item.set == filter.set);
    let searchedImages = items;
    if (search.length >= 1){
      searchedImages = items.filter((item) => item.id.includes(search));
    };
    setImages(searchedImages);
  }, [search]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <GalleryNav
        filter={filter}
        updateFilter={updateFilter}
        clearFilter={clearFilter}
        isms={props.isms}
        search={search}
        setSearch={setSearch}
      />
      <GalleryGrid images={images} set={filter.set} />
    </Box>
  );
}
