import React, { useState } from 'react';

import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ClearIcon from '@mui/icons-material/Clear';

import Basic from './Basic';
import Advanced from './Advanced';


export default function GalleryNav(props) {
  const [advancedOpen, setAdvancedOpen] = useState(false);

  // Set height explicitly to make animation work
  // height: advancedOpen ? 331 : 139,


  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 20,
        transitionDuration: '0.3s',
      }}
    >
      <Basic
        filter={props.filter}
        updateFilter={props.updateFilter}
        isms={props.isms}
        setSearch={props.setSearch}
      />
      {
        advancedOpen && (
          <Advanced
            isms={props.isms}
            filter={props.filter}
            updateFilter={props.updateFilter}
          />
        )
      }
      <ButtonGroup variant="text" aria-label="filter options" sx={{mt:2}}>
        <Button 
          aria-label="advanced-options" 
          onClick={() => setAdvancedOpen(prev => !prev)} 
          endIcon={ advancedOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
          sx={{px:2}}
        >
          Advanced
        </Button>
        {
          advancedOpen && (
            <Button 
              aria-label="advanced-options" 
              onClick={() => props.clearFilter()} 
              endIcon={<ClearIcon />}
              sx={{px:2}}
            >
              Clear
            </Button>
          )
        }
      </ButtonGroup>
      
    </div>
  );
}
