import React from 'react';

import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


export default function ToggleGroup(props) {

  console.log('type', props.type);
  console.log('value', props.value);

  return (
    <Box 
      sx={{ 
        marginBottom: `10px`,
        px: 1,
        width: props.width ? props.width : '100%',
        minWidth: props.minWidth ? props.minWidth : '100%',
        flexGrow: 1,
      }}
    >
      <div
        style={{
          color: '#d3d3d3',
          marginBottom: 5,
        }}
      >
        { props.label }
      </div>
      <ToggleButtonGroup
        value={props.value}
        exclusive
        onChange={(event, newValue) => {
          if(newValue != null){
            props.setValue && props.setValue(newValue);
            props.updateValue && props.updateValue(props.type, newValue);
          }
        }}
        aria-label={props.label}
        fullWidth
        size='small'
      >
        {
          props.options.map((option, oi) => (
            <ToggleButton key={`${props.label}-${oi}`} value={option.value} aria-label={option.name}>
              { option.name }
            </ToggleButton>
          ))
        }
      </ToggleButtonGroup>
    </Box>
  );
}
