import React from 'react';

import { Tile, TileOverlay, Text } from './tiles/Tiles.js';
import ZoomText from './tiles/ZoomText';
import PopText from './tiles/PopText';
import FlipXText from './tiles/FlipXText';
import FlipYText from './tiles/FlipYText';
import FlipXTile from './tiles/FlipXTile';

export default function Item(props) {

  let bg = !props.bg ? '#FFFFFF00' : (
    !props.item.highlight && !props.invert
  ) ? props.item.traits.backgroundColor : (
    !props.item.highlight && props.invert
  ) ? props.item.traits.textColor : (
    props.item.highlight && !props.invert
  ) ? `linear-gradient(${props.item.highlightObj.angle}, ${props.item.traits.backgroundColor} ${props.item.highlightObj.intensity}, rgba(255,255,255,0) 100%)` : (
    props.item.highlight && props.invert
  ) ? `linear-gradient(${props.item.highlightObj.angle}, ${props.item.traits.textColor} ${props.item.highlightObj.intensity}, rgba(255,255,255,0) 100%)` : '';

  let t = props.invert ? props.item.traits.backgroundColor : props.item.traits.textColor;



  let length = props.length - (2 * props.margin);
  let contentLength = length * 0.9;

  let properties = {
    weight: props.item.traits.weight,
    font: props.item.traits.font,
    background: bg,
    text: t,
    lineHeight: props.item.lineHeight,
    textMultiple: props.item.textMultiple,
    fontSize: length * props.item.textMultiple * (126/1000),
  };

  const animations = {
    breathe: ZoomText,
    pulse: PopText,
    flipx: FlipXText,
    flipy: FlipYText,
  };

  let txt = props.t ? props.item.traits.phrase : '';

  const tile = (      
    <Tile
      length={length}
      margin={props.margin}
      properties={properties}
    >
      <Text
        length={contentLength}
      >
        {
          props.item.animation === '' ? (
            <>{txt}</>
          ) : props.item.animation === 'breathe' ? (
            <ZoomText length={contentLength} values={props.item.animationObj} >
              {txt}
            </ZoomText>
          ) : props.item.animation === 'pulse' ? (
            <PopText length={contentLength} values={props.item.animationObj} >
              {txt}
            </PopText>
          ) :  props.item.animation === 'flipx' ? (
            <FlipXText length={contentLength} values={props.item.animationObj} >
              {txt}
            </FlipXText>
          ) :  props.item.animation === 'flipy' ? (
            <FlipYText length={contentLength} values={props.item.animationObj} >
              {txt}
            </FlipYText>
          ) : ''
        }
      </Text>
      <TileOverlay length={length}>
        {
          !props.item.verified && (
            props.item.iid
          )
        }
      </TileOverlay>
    </Tile>
  );

  if (props.flip){
    return (
      <FlipXTile length={length} >
        {tile}
      </FlipXTile>
      
  
    );
  } else {
    return (
      <>
        {tile}
      </>
    )
  }

}
