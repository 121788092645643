import React from 'react';
import _, { map } from 'underscore';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import { Tile } from '../utils/Tile';
import { InfoRow } from '../utils/InfoRow';
import { InfoRowItem } from '../utils/InfoRowItem';

import Palettes from './Palettes';
import Fonts from './Fonts';
import Collections from './Collections';
import Phrases from './Phrases';

const ismsDataDict = require('../../../../../static/properties/isms.json');


export default function Info(props) {
  const ismIds = Object.keys(ismsDataDict);

  const palletIsmIds = ismIds.filter(id => id.startsWith(props.palette));

  const randomIds = _.sample(palletIsmIds, 3);

  return (
    <Box sx={{
      display: 'block',
      p: 6,
      mb: '20px',
      width: '100%',
    }}>
      <h1 style={{width:'100%',textAlign:'center'}}>Building blocks for personalized digital art.</h1>
      <p style={{width:'100%',textAlign:'center'}}>Isms is based upon mantras, idioms, and euphomisms of new and old, inspired by the spirit of internet natives and the crypto community.</p>
      <p style={{width:'100%',textAlign:'center'}}>Each NFT in the xxxxx item set was algorithmically generated from a combination of phrases, fonts, and colors to create a unique essence.</p>

      <Palettes />
      <Fonts />
      <Collections />
      <Phrases ismsData={props.ismsData} />
  

    </Box>
  );
}
