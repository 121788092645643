import React from 'react';
import { styled } from '@mui/material/styles';


export const InfoRowItem = styled('div', { shouldForwardProp: (prop) => prop !== 'width' })(
  ({ theme }) => ({
    width: '50%',
    height: '100%',
    flexGrow: 1,
    minWidth: 360,
  }),
);
