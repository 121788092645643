import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function PaletteToggle(props) {

  return (
    <Box
      sx={{
        width: '50%',
        minWidth: 200,
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FormControl  variant="standard" sx={{ width: 300, m: 1, }}>
        <ToggleButtonGroup
          size='small'
          value={props.palette}
          exclusive
          onChange={(event, newValue) => {
            if(props.iid.length > 1){
              let formattedNumber = props.iid.substring(1).toLocaleString('en-US', {
                minimumIntegerDigits: 4,
                useGrouping: false
              });
              props.updatePalette(newValue + formattedNumber, newValue);
              props.setIid(newValue + formattedNumber);
            } else {
              props.updatePalette(false, newValue);
            }
          }}
          aria-label="palette select"
          fullWidth
        >
          <ToggleButton value='P' aria-label="pastel">
            pastels
          </ToggleButton>
          <ToggleButton value='S' aria-label="slate">
            slates
          </ToggleButton>
          <ToggleButton value='C' aria-label="coral">
            coral
          </ToggleButton>
          <ToggleButton value='F' aria-label="forest">
            forest
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
    </Box>

  );
}
