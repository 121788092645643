import * as React from 'react';

import { makeStyles } from '@mui/styles';
import Fade from '@mui/material/Fade';

const useStyles = makeStyles((theme) => ({
  itemConfigRoot: {
    width: '100%',

  },


}));

export default function Items(props) {
  const classes = useStyles();

  return (
    <Fade in={true}>
      <div
        className={classes.itemConfigRoot}
        style={{
          display: 'block',
        }}
      >
        {props.children}
      </div>
    </Fade>
  );
}
