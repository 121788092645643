import * as React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';

import HighlightConfig from './HighlightConfig';

export default function Static(props) {


  const handleChange = (event) => {
    props.updateValue([event.target.name], !props.item[event.target.name]);
  };



  return (
    <FormControl 
      component="fieldset" 
      variant="standard"
      sx={{
        width: '50%',
        minWidth: 200,
        flexGrow: 1,
      }}
    >
      <FormLabel component="legend">Static customization</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={props.item.invert} onChange={handleChange} name="invert" />
          }
          label="Invert"
        />
        <FormControlLabel
          control={
            <Switch checked={!props.item.text} onChange={handleChange} name="text" />
          }
          label="Toggle Text"
        />
        <FormControlLabel
          control={
            <Switch checked={!props.item.background} onChange={handleChange} name="background" />
          }
          label="Toggle Background"
        />
        <FormControlLabel
          control={
            <Switch checked={props.item.highlight} onChange={handleChange} name="highlight" />
          }
          label="Background Highlight"
        />
      </FormGroup>
      {
        props.item.highlight && (
          <HighlightConfig 
            highlight={props.item.highlight}
            highlightObj={props.item.highlightObj}
            updateValue={props.updateValue}
          />
        )
      }
      <FormHelperText>Be careful</FormHelperText>
    </FormControl>
  );
}