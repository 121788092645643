import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';

import IntervalSwitch from './IntervalSwitch';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    flexGrow: 0,
    width: 100,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function IntervalConfig(props) {
  const [invert, setInvert] = useState(props.invert);
  const [toggle, setToggle] = useState(props.invert);
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const intervalMap = {
    1000: '1 second',
    2000: '2 seconds',
    5000: '5 seconds',
    10000: '10 seconds',
  };

  const updateItemIntervals = (time, action, value) => {
    let newItem = { ...props.item };
    newItem.intervals[time][action] = value;
    console.log('update intervals running', props.order);
    props.updateItem(props.order, 'intervals', newItem.intervals);
  }

  return (
    <div style={{ width: '100%' }}>
      {
        props.intervals.map(([time, intervalObj], idx) => (
          <Accordion
            key={`interval-config-${idx}`}
            expanded={expanded === `interval-config-${time}`}
            onChange={handleChange(`interval-config-${time}`)}
          >
            <AccordionSummary
              aria-controls={intervalObj.name}
              id={`interval-config-${time}`}
            >
              <Typography>{ intervalObj.name }</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                <IntervalSwitch
                  key={`${time}-${props.order}-invert`}
                  value={props.item.intervals[time].invert}
                  time={time}
                  action='invert'
                  order={props.order}
                  label='Invert'
                  updateItemIntervals={updateItemIntervals}
                  updateIntervalMap={props.updateIntervalMap}
                />
                <IntervalSwitch
                  key={`${time}-${props.order}-toggle`}
                  value={props.item.intervals[time].toggle}
                  time={time}
                  action='toggle'
                  order={props.order}
                  label='Toggle'
                  updateItemIntervals={updateItemIntervals}
                  updateIntervalMap={props.updateIntervalMap}
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        ))
      }
    </div>
  );
}
