import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import _, { map } from 'underscore';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Controls from './Controls';
import TileBlock from './TileBlock';

import { intervalArr, itemIntervalObj } from '../../../../../static/lab/objects';
const ismsData = require('../../../../../static/properties/isms.json');

const baseItem = {
  iid: '',
  verified: false,
  palette: 'P',
  textMultiple: 1,
  lineHeight: 1.1,
  text: true,
  background: true,
  invert: false,
  highlight: false,
  highlightObj: {color: '', angle: '0deg', intensity: '50%'},
  animation: '',
  animationObj: {type: 1, duration: '5s', shadow: false},
  traits: {},
  intervals: {...itemIntervalObj},
};



export default function Demo(props) {



  const [item, setItem] = useState(baseItem);
  const [itemState, setItemState] = useState({t: true, bg:true, invert: false, toggle: false});
  

  const setTraits = () => {

    const random = _.sample(ismsData, 1);
    let newItem = {...item};
    newItem.traits = random[0];
    setItem(newItem);
    console.log('reaits', newItem);

  }

  const updateItem = (order, key, value) => {
    let newItem = {...item};
    newItem[key] = value;
    setItem(newItem);
  };

  useEffect(() => {
    setTraits();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        mt: 4,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      <Controls item={item} updateItem={updateItem} setTraits={setTraits} />
      <TileBlock item={item} updateItem={updateItem} itemState={itemState} />

    </Box>
  );
}
