import * as React from 'react';

import { NavLink } from "react-router-dom";

import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import StarBorder from '@mui/icons-material/StarBorder';

import InfoIcon from '@mui/icons-material/Info';
import StartIcon from '@mui/icons-material/Start';
import GridViewIcon from '@mui/icons-material/GridView';
import CropSquareIcon from '@mui/icons-material/CropSquare';

import logo from '../../../../../static/lab/images/logo.png';

export default function InfoMenuMobile(props) {


  return (
    <Box 
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        px: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <img src={logo} width='24px' alt="logo" style={{marginRight:32 }}/>
      <FormControl sx={{ m: 1, minWidth: 180 }}>
        <Select defaultValue={1} id="grouped-select" label="Grouping" variant='standard'>
          <MenuItem 
            value={1}
            component={NavLink}
            to='/info'
          >
            <Box sx={{display: 'flex', alignItems: 'center',}}>
              <ListItemIcon>
                <InfoIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Intro</ListItemText>
            </Box>          
          </MenuItem>
          <MenuItem 
            value={2}
            component={NavLink}
            to='/info/getting-started'
          >
            <Box sx={{display: 'flex', alignItems: 'center',}}>
              <ListItemIcon>
                <StartIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Getting Started</ListItemText>
            </Box>          
          </MenuItem>
          <ListSubheader>Settings</ListSubheader>
          <MenuItem 
            value={3}
            component={NavLink}
            to='/info/grid'
          >
            <Box sx={{display: 'flex', alignItems: 'center',}}>
              <ListItemIcon>
                <GridViewIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Grid</ListItemText>
            </Box>          
          </MenuItem>
          <MenuItem 
            value={4}
            component={NavLink}
            to='/info/items'
          >
            <Box sx={{display: 'flex', alignItems: 'center',}}>
              <ListItemIcon>
                <CropSquareIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Items</ListItemText>
            </Box>          
          </MenuItem>
          <MenuItem 
            value={5}
            component={NavLink}
            to='/info/connect'
          >
            <Box sx={{display: 'flex', alignItems: 'center',}}>
              <ListItemIcon>
                <StarBorder fontSize="small" />
              </ListItemIcon>
              <ListItemText>Connect</ListItemText>
            </Box>          
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
    
  );
}
