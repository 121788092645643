import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';


import AnimationOptions from './AnimationOptions';

export default function Animation(props) {


  const handleChange = (event) => {
    props.updateValue([event.target.name], event.target.value);
  };


  return (
    <FormControl
      sx={{
        width: '50%',
        minWidth: 200,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <Box 
        sx={{ 
          width: '50%',
          minWidth: 160,
          flexGrow: 1, 
        }}
      >
        <FormLabel id="demo-controlled-radio-buttons-group">Animation</FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="animation"
          value={props.item.animation}
          onChange={handleChange}
        >
          <FormControlLabel value="" control={<Radio />} label="None" />
          <FormControlLabel value="breathe" control={<Radio />} label="Breathe" />
          <FormControlLabel value="pulse" control={<Radio />} label="Pulse" />
          <FormControlLabel value="flipy" control={<Radio />} label="Flip Horizontal" />
          <FormControlLabel value="flipx" control={<Radio />} label="Flip Vertical" />
        </RadioGroup>
      </Box>
      
      {
        props.item.animation !== '' && (
          <AnimationOptions values={props.item.animationObj} updateValue={props.updateValue} />
        )
      }
    </FormControl>
  );
}