import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import ToggleGroup from '../../../../layout/inputs/ToggleGroup';


export default function HighlightConfig(props) {
  const [angle, setAngle] = useState(props.highlightObj.angle);
  const [intensity, setIntensity] = useState(props.highlightObj.intensity);

  const handleHighlightChange = (type, stateFxn, val) => {
    stateFxn(val);
    let newHilightObj = {...props.highlightObj};
    newHilightObj[type] = val;
    props.updateValue('highlightObj', newHilightObj);
  }

  const angleOptions = [0,30,45,60,90,120,135,150,180,210,225,240,270,300,315,330];
  const intentistyOptions = [10,20,30,40,50,60,70,80,90];

  return (
    <Box 
      sx={{ 
        width: '50%',
        minWidth: 240, 
      }}
    >
      {
        props.highlight == true && (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <FormControl variant="standard" sx={{ mx: 1, width: 'calc(50% - 16px)' }}>
              <InputLabel id="highlight-angle-label">Angle</InputLabel>
              <Select
                labelId="highlight-angle-label"
                id="highlight-angle-select"
                value={angle}
                onChange={(e) => handleHighlightChange('angle', setAngle, e.target.value)}
                label="Angle"
              >
                {
                  angleOptions.map(ang => (
                    <MenuItem key={`${ang}deg`} value={`${ang}deg`}>{`${ang}\u00b0`}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ mx: 1, width: 'calc(50% - 16px)'}}>
              <InputLabel id="highlight-intensity-label">Intensity</InputLabel>
              <Select
                labelId="highlight-intensity-label"
                id="highlight-intensity-select"
                value={intensity}
                onChange={(e) => handleHighlightChange('intensity', setIntensity, e.target.value)}
                label="Intensity"
              >
                {
                  intentistyOptions.map(inten => (
                    <MenuItem key={`${inten}%`} value={`${100-inten}%`}>{`${inten}%`}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        )
      }
      
    </Box>
  );
}
