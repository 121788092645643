import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import Explore from './Explore';


export default function Steps(props) {



  return (
    <Box 
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Explore />
     
    </Box>
  );
}
