import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function PaletteToggle(props) {


  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mb: '20px',
      }}
    >
      <Tooltip title="More">
        <IconButton aria-label="left" onClick={() => {}}>
          <ArrowBackIosNewIcon />
        </IconButton>
      </Tooltip>
      <FormControl  variant="standard" sx={{ width: 300, mx: 5, }}>
        <ToggleButtonGroup
          size='small'
          value={props.palette}
          exclusive
          onChange={(event, newValue) => props.setPalette(newValue)}
          aria-label="palette select"
          fullWidth
        >
          <ToggleButton value='P' aria-label="pastels">
            pastels
          </ToggleButton>
          <ToggleButton value='S' aria-label="slates">
            slates
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
      <Tooltip title="Less">
        <IconButton aria-label="right" onClick={() => {}}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Tooltip>
    </Box>

  );
}
