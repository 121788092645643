import React from 'react';
import { makeStyles } from '@mui/styles';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import LabelItem from './LabelItem';

import { css } from '@emotion/react';

const useStyles = makeStyles((theme) => ({
  previewInfoRoot: {
    width: 'calc(100% - 200px)',
    padding: '0px 10px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  previewInfoRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 10,
    marginBottom: 10,
  },
  piLabel: {
    width: 120,
    color: '#d3d3d3',
    textAlign: 'left',
  },
  piValue: {
    color: '#545e6f',
    textAlign: 'right',
  },

}));

export default function PreviewInfo(props) {
  const classes = useStyles();

  return (
    <Box 
      sx={{
        flexGrow: 1,
        width: 'calc(100% - 200px)',
        minWidth: 200,
        padding: '0px 10px',
        display: 'flex',
        flexWrap: 'wrap',
      }}>
      <LabelItem 
        label='Phrase'
        value={props.item.phrase ? props.item.phrase : '--'}
      />
      <Divider sx={{width:'100%'}} />
      <LabelItem 
        label='Set'
        value={props.item.set ? props.item.set : '--'}
      />
      <Divider sx={{width:'100%'}} />
      <LabelItem 
        label='Font'
        value={props.item.font ? props.item.font : '--'}
      />
      <Divider sx={{width:'100%'}} />
      <LabelItem 
        label='Text'
        value={props.item.textColor ? props.item.textColor : '--'}
        width='50%'
      />
      <LabelItem 
        label='Background'
        value={props.item.backgroundColor ? props.item.backgroundColor : '--'}
        width='50%'
      />
    </Box>

  );
}
