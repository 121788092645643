import React, { useRef } from 'react';
import { useLocation, useParams } from "react-router-dom";

import Box from '@mui/material/Box';


import AboutNav from './AboutNav';
import AboutContent from './AboutContent';
import Collections from './collections/Collections';

import intro from '../../../../docs/intro.md';
import theNft from '../../../../docs/the-nft.md';
import lab from '../../../../docs/lab.md';



export default function About(props) {
  let { docId } = useParams();

  const { pathname } = useLocation();
  console.log('pathname', pathname);

  const introRef = useRef();
  const theNftRef = useRef();
  const labRef = useRef();

  const docs = [
    {
      id: 'intro',
      name: 'Intro',
      doc: intro,
      ref: introRef,
      href: '/about/intro',
    },
    {
      id: 'lab',
      name: 'Lab',
      doc: lab,
      ref: labRef,
      href: '/about/lab',
    },
    {
      id: 'collections',
      name: 'Collections',
      doc: theNft,
      ref: undefined,
      component: <Collections isms={props.isms} />,
      href: '/about/collections',
    },
    {
      id: 'the-nft',
      name: 'The NFT',
      doc: theNft,
      ref: theNftRef,
      href: '/about/the-nft',
    },
  ];


  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 200px)',
        padding: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
      }}
    >
      <AboutNav docs={docs} />
      <AboutContent docId={docId} docs={docs} isms={props.isms} />
    </Box>

  );
}
