import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";



import Controller from './controller/Controller';


function Lab() {

  useEffect(() => {

  }, []);

  return (
    <Router>
      <div style={{
        width: '100%',
        height: '100vh',
      }}>
        <Routes>
          <Route element={<Controller />} path="*" />
        </Routes>
      </div>
    </Router>
  );
}

export default Lab;
