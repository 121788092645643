import React from 'react';
import { NavLink } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

export default function AboutNav(props) {

  const scrollToRef = (ref) => window.scrollTo({
    behavior: "smooth",
    top: ref.current.offsetTop
  });

  return (
    <Box
      sx={{
        width: 280,
        pr: 5,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {
        props.docs.map((doc, di) => (
          <Typography
            key={`docs-${di}`}
            variant='h4'
            color='primary'
            component={NavLink}
            to={doc.href}
            style={({ isActive }) => ({
              color: isActive ? '#545e6f' : '#d4d4d4',
              fontFamily: 'Spartan',
              textDecoration: 'none',
              marginBottom: 20,
            })}
          >
            { doc.name }
          </Typography>
        ))
      }
      <TextField
        id="docs-search"
        label="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
    </Box>
  );
}
