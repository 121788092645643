import React from 'react';

import { css, keyframes } from "@emotion/react";
import Box from '@mui/material/Box';


export default function FlipXTile(props) {

  const flipxEffect = keyframes`
    0% {
      opacity: 1;
      transform: rotateX(90def);
    }
    50% {
      opacity: 1;
      transform: rotateX(720deg);
    }
    100% {
      /* animate nothing to pause animation at the end */
      opacity: 1;
      transform: rotateX(720deg);
    }
  `;

  return (
    <Box
      sx={{
        animation: `330ms ${flipxEffect} ease 1`,
      }}
    >
      {props.children}
    </Box>

  );
}
