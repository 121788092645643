import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import { BasicTile } from '../../../display/grid/tiles/Tiles';

const ismsData = require('../../../../../../static/lab/properties/isms.json');


export default function Explore(props) {


  const ps = ['P','S','C','F'];

  const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const getRandom = () => {
    let intNum = randomIntFromInterval(0,6191);
    const randomP = ps[Math.floor(Math.random() * ps.length)];
    console.log('intnum', intNum);
    let formattedNumber = intNum.toLocaleString('en-US', {
      minimumIntegerDigits: 4,
      useGrouping: false
    });
    let iid = randomP + formattedNumber;
    return iid;
  }

  const [item, setItem] = useState(ismsData[getRandom()]);

  useEffect(() => {
      let newInterval = setInterval(() => {
        let newIid = getRandom();
        var pullItem = ismsData[newIid];
        console.log('newid', newIid);
        console.log('newitem', pullItem);
        setItem(pullItem);
        
      }, 1500);
      
    return () => {
      clearInterval(newInterval);
    }
  }, [props.intervalMap, props.itemStates]);


  return (
    <Box 
      sx={{
        width: '100%',
      }}
    >
      <h3>I Explore</h3>
      <BasicTile
        length={200}
        properties={item}
      >
        {item.phrase}
      </BasicTile>
      <p>Easily search or browse the collection for inspiration.</p>
     
    </Box>
  );
}
