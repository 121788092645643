import React, { useState } from 'react';

import Box from '@mui/material/Box';

import Hero from './hero/Hero';
import PaletteToggle from './PaletteToggle';
import Info from './info/Info';
import TheLab from './TheLab';

export default function Home(props) {
  const [palette, setPalette] = useState('P');
  const [width, setWidth] = useState(window.innerWidth);

  // Window resizing
  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Hero palette={palette} mobile={props.mobile} width={width} />
      <PaletteToggle palette={palette} setPalette={setPalette} />
      <Info width={width} palette={palette} isms={props.isms} ismsData={props.ismsData}/>
      <TheLab />
    </Box>
  );
}
