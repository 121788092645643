import React from 'react';
import _, { map } from 'underscore';

import Box from '@mui/material/Box';
import CyclingPhrase from '../utils/CyclingPhrase';



export default function Phrases(props) {

  const randomSet = _.sample(props.ismsData, 50);


  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mb: '20px',
    }}>
      <h3 style={{width:'100%',textAlign:'center'}}>490 Phrases</h3>
      <CyclingPhrase interval={3000} font={false} items={randomSet} />

    </Box>
  );
}
