import React from 'react';
import { useParams } from "react-router-dom";

import Box from '@mui/material/Box';

import CollectionsNav from "./CollectionsNav"
import Collection from "./Collection"
import CollectionTiles from "./CollectionTiles"


export default function Collections(props) {
  let { type } = useParams();

  const ctype = type ? type : 'absurdism';

  let collection = props.isms.find(ism => ism.iid == ctype);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
      }}
    >
      <CollectionsNav isms={props.isms} type={ctype} />
      <Collection type={ctype} collection={collection.phrases} />
    </Box>
  );
}
