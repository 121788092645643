import React from 'react';

import { css, keyframes } from "@emotion/react";
import Box from '@mui/material/Box';


export default function ZoomText(props) {

  const zoomEffectShadow = keyframes`
    0% {
      transform: scale(1);
      opacity: 1;
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    50% {
      transform: scale(1.1);
      opacity: 1;
      text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
    }
    100% {
      /* animate nothing to add pause at the end of animation */
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
  `;

  const zoomEffect = keyframes`
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      /* animate nothing to add pause at the end of animation */
      transform: scale(1);
      opacity: 1;
    }
  `;

  return (
    <Box
      sx={{
        animation: `${props.values.duration} ${zoomEffect} ease infinite`,
      }}
    >
      {props.children}
    </Box>

  );
}
