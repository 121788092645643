import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';

import { exportComponentAsPNG } from 'react-component-export-image';


const useStyles = makeStyles((theme) => ({
  exportsRoot: {
    width: '100%',

  },

}));

export default function Exports(props) {
  const classes = useStyles();

  return (
    <div
      className={classes.exportsRoot}
    >
      <Button onClick={() => exportComponentAsPNG(props.gridRef)}>
        Export As PNG
      </Button>

    </div>
  );
}
