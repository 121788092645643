import React from 'react';

import { styled } from '@mui/material/styles';
import { css, keyframes } from "@emotion/react";

export const Tile = styled('div', { shouldForwardProp: (prop) => prop !== 'properties' && prop !==  'length' && prop !==  'margin' })(
  ({ theme, properties, length, margin }) => ({
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: length,
    height: length,
    padding: length * 0.05,
    margin: margin,
    textAlign: 'center',
    fontSize: properties.fontSize || length * (126/1000),
    fontWeight: properties.weight || 1,
    lineHeight: properties.lineHeight || `${length * (132/1000)}px`,
    fontFamily: properties.font,
    background: properties.background,
    color: properties.text,
    border: !properties.background ? '1px solid #d3d3d3' : '',
  }),
);

export const BasicTile = styled('div', { shouldForwardProp: (prop) => prop !== 'properties' && prop !==  'length' && prop !==  'margin' })(
  ({ theme, properties, length, margin }) => ({
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: length,
    height: length,
    padding: length * 0.05,
    textAlign: 'center',
    fontSize: length * (126/1000),
    lineHeight: `${length * (132/1000)}px`,
    fontFamily: properties.font,
    background: properties.backgroundColor,
    color: properties.textColor,
    border: !properties.backgroundColor ? '1px solid #d3d3d3' : '',
  }),
);

export const TileOverlay = styled('div', { shouldForwardProp: (prop) => prop !== 'length' })(
  ({ theme, length }) => ({
    position: 'absolute',
    fontFamily: 'Spartan',
    lineHeight: 1,
    bottom: 10,
    right: 10,
    fontSize: length * (50.4/1000),
  }),
);

// Text

export const Text = styled('div', { shouldForwardProp: (prop) => prop !== 'length' })(
  ({ theme, length }) => ({
    width: length,
    height: length,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

const popEffect = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  25% {
    transform: scale(1.1);
    opacity: 1;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  50% {
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    /* animate nothing to add pause at the end of animation */
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
`;

export const PopText = styled('div', { shouldForwardProp: (prop) => prop !== 'length' })(
  ({ theme, length }) => ({
    animation: `2s ${popEffect} ease infinite`,
  }),
);

const zoomEffectShadow = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
    text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
  }
  100% {
    /* animate nothing to add pause at the end of animation */
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
`;

const zoomEffect = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    /* animate nothing to add pause at the end of animation */
    transform: scale(1);
    opacity: 1;
  }
`;

export const ZoomText = styled('div', { shouldForwardProp: (prop) => prop !== 'length' })(
  ({ theme, length }) => ({
    animation: `10s ${zoomEffect} ease infinite`,
  }),
);

const flipxEffect = keyframes`
  0% {
    opacity: 1;
    transform: rotateX(90def);
  }
  50% {
    opacity: 1;
    transform: rotateX(720deg);
  }
  100% {
    /* animate nothing to pause animation at the end */
    opacity: 1;
    transform: rotateX(720deg);
  }
`;

export const FlipXText = styled('div', { shouldForwardProp: (prop) => prop !== 'length' })(
  ({ theme, length }) => ({
    width: length,
    height: length,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    animation: `5s ${flipxEffect} ease infinite`,
  }),
);

const flipySpin = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  30% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(-360deg);
  }
`;

const flipyDepth = keyframes`
  0% {
    text-shadow: 0 0 white;
  }
  25% {
    text-shadow: 1px 0 white, 2px 0 white, 3px 0 white, 4px 0 white, 5px 0 white;
  }
  50% {
    text-shadow: 0 0 white;
  }
  75% {
    text-shadow: -1px 0 white, -2px 0 white, -3px 0 white, -4px 0 white,
      -5px 0 white;
  }
  100% {
    text-shadow: 0 0 white;
  }
`;

export const FlipYText = styled('div', { shouldForwardProp: (prop) => prop !== 'length' })(
  ({ theme, length }) => ({
    width: length,
    height: length,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    animationName: `${flipySpin}, ${flipyDepth}`,
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    animationDuration: '6s',
  }),
);
