import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function SettingsFab(props) {

  return (
    <Tooltip title="Settings" placement="left">
      <IconButton
        aria-label='settings'
        sx={{
          position: 'absolute',
          bottom: 'calc(50% - 20px)',
          right: 16,
          bottom: 16,
          color: '#d3d3d3',
        }}
        color='inherit'
        onClick={() => props.setSettings(prev => !prev)}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
    </Tooltip>
  );
}
