import React from 'react';

import { css, keyframes } from "@emotion/react";
import Box from '@mui/material/Box';


export default function PopText(props) {

  const popEffect = keyframes`
    0% {
      transform: scale(1);
      opacity: 1;
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    25% {
      transform: scale(1.1);
      opacity: 1;
      text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    }
    50% {
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      /* animate nothing to add pause at the end of animation */
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
  `;

  return (
    <Box
      sx={{
        animation: `${props.values.duration} ${popEffect} ease infinite`,
      }}
    >
      {props.children}
    </Box>

  );
}
