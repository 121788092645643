import React from 'react';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CachedIcon from '@mui/icons-material/Cached';

export default function ItemSearch(props) {

  const handleKeypress = e => {
      // it triggers by pressing the enter key
      if (e.charCode === 13) {
      props.handleSubmit();
    }
  };

  const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const handleRandom = () => {
    let intNum = randomIntFromInterval(0,3941);
    console.log('intnum', intNum);
    let formattedNumber = intNum.toLocaleString('en-US', {
      minimumIntegerDigits: 4,
      useGrouping: false
    });
    props.setIid(props.palette + formattedNumber);
    props.handleChange(props.palette + formattedNumber);
    // props.handleSubmit();
  }

  return (
    <div
      style={{
        width: '50%',
        minWidth: 200,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FormControl  variant="standard" sx={{ m: 1, width: 240 }}>
        <TextField
          id="display-search"
          label="Search"
          variant="standard"
          value={props.iid.substring(1)}
          onChange={(e) => {
            let intNum = parseInt(e.target.value);
            let formattedNumber = intNum.toLocaleString('en-US', {
              minimumIntegerDigits: 4,
              useGrouping: false
            });
            props.setIid(props.palette + formattedNumber);
            props.handleChange(props.palette + formattedNumber);
          }}
          onKeyPress={handleKeypress}
          inputProps={{style: {fontSize: 22, fontFamily: 'Spartan'}}}
          InputLabelProps={{style: {fontSize: 22, fontFamily: 'Spartan'}}}
          onFocus={event => {
            event.target.select();
          }}
          autoComplete='off'
          type='text'
          inputRef={input => input && input.focus()}
        />
      </FormControl>
      <IconButton
        aria-label="go"
        onClick={props.handleSubmit}
      >
        <ArrowForwardIosIcon />
      </IconButton>
      <IconButton
        aria-label="go"
        onClick={handleRandom}
      >
        <CachedIcon />
      </IconButton>

    </div>
  );
}
