import React from 'react';

import Box from '@mui/material/Box';

import { css } from '@emotion/react';

export default function LabelItem(props) {

  return (
    <Box 
      sx={{
        width: props.width || '100%',
        display: 'block',
        marginTop: 1,
        marginBottom: 1,
      }}
    >
      <Box 
        sx={{
          width: props.width,
          color: '#d3d3d3',
          textAlign: 'left',
          lineHeight: 1,
        }}
      >
          { props.label }
      </Box>
      <Box 
        sx={{
          width: props.width,
          color: '#545e6f',
          textAlign: 'left',
          lineHeight: 1.2,
        }}
      >
          { props.value }
      </Box>
    </Box>

  );
}
