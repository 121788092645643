

export const itemIntervalObj = {
  1000:{
    invert: false,
    toggle: false,
  },
  2000:{
    invert: false,
    toggle: false,
  },
  5000:{
    invert: false,
    toggle: false,
  },
  10000:{
    invert: false,
    toggle: false,
  },
  30000:{
    invert: false,
    toggle: false,
  },
  60000:{
    invert: false,
    toggle: false,
  },
};

export const intervalArr = [
  [1000, {
    name: '1 second',
    interval: undefined,
    time: 0,
  }],
  [2000, {
    name: '2 seconds',
    interval: undefined,
    time: 0,
  }],
  [5000, {
    name: '5 seconds',
    interval: undefined,
    time: 0,
  }],
  [10000, {
    name: '10 seconds',
    interval: undefined,
    time: 0,
  }],
  [30000, {
    name: '30 seconds',
    interval: undefined,
    time: 0,
  }],
  [60000, {
    name: '1 minute',
    interval: undefined,
    time: 0,
  }],
];
