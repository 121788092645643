import React from 'react';

import Box from '@mui/material/Box';
import PreviewTile from './PreviewTile';
import PreviewInfo from './PreviewInfo';


export default function ItemPreview(props) {

  return (
    <Box
      sx={{
        width: '100%',
        py: 4,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <PreviewTile
        length={props.length}
        item={props.itemState}
        invert={props.item.invert}
      />
      <PreviewInfo
        length={props.length}
        item={props.itemState}
      />
    </Box>

  );
}
