import React from 'react';
import { Routes, Route } from "react-router-dom";

import Box from '@mui/material/Box';

import Doc from './docs/Doc';
import Collections from './collections/Collections';


export default function AboutContent(props) {


  return (
    <Box
      sx={{
        width: 'calc(100% - 280px)',
        height: '100%',
        px: 5,
        display: 'flex',
        flexDirection: 'column',
        borderLeft: '1px solid #d4d4d4',
        overflowY: 'auto',
      }}
    >
      <Routes>
        <Route element={<Doc isms={props.isms} doc={props.docs[0]} />} path={`/`} />
        <Route element={<Doc isms={props.isms} doc={props.docs[0]} />} path={`/intro`} />
        <Route element={<Doc isms={props.isms} doc={props.docs[1]} />} path={`/lab`} />
        <Route element={<Doc isms={props.isms} doc={props.docs[3]} />} path={`/the-nft`} />
        <Route element={<Collections isms={props.isms} />} path='/collections' />
        <Route element={<Collections isms={props.isms} />} path='/collections/:type' />
      </Routes>
    </Box>
  );
}
