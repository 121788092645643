import React from 'react';

import { css, keyframes } from "@emotion/react";
import Box from '@mui/material/Box';


export default function FlipYText(props) {

  const flipySpin = keyframes`
    0% {
      transform: rotateY(0deg);
    }
    30% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(-360deg);
    }
  `;

  const flipyDepth = keyframes`
    0% {
      text-shadow: 0 0 white;
    }
    25% {
      text-shadow: 1px 0 white, 2px 0 white, 3px 0 white, 4px 0 white, 5px 0 white;
    }
    50% {
      text-shadow: 0 0 white;
    }
    75% {
      text-shadow: -1px 0 white, -2px 0 white, -3px 0 white, -4px 0 white,
        -5px 0 white;
    }
    100% {
      text-shadow: 0 0 white;
    }
  `;

  return (
    <Box
      sx={{
        animationName: `${flipySpin}, ${flipyDepth}`,
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        animationDuration: props.values.duration,
      }}
    >
      {props.children}
    </Box>

  );
}
