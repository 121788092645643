import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';



export default function CyclingPhrase(props) {

  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState(props.items);

  useEffect(() => {
    const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
      setLoading(true);
      setTimeout(() => {
        setActive(prev => prev + 1 < items.length ? prev + 1 : 0);
      },250);
      setTimeout(() => {
        setLoading(false);
      },500);
    }, props.interval);

    console.log('int items', props.items);
    return () => clearInterval(intervalId); //This is important

  }, [props.items])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      {
        props.items.length > 0 && (
          <Fade in={!loading}>
            <Typography
              variant='h4'
              color='secondary'
              sx={{ fontFamily: props.font ? items[active].font : 'Spartan' }}
            >
              {
                props.font ? items[active].name : items[active].phrase
              }
            </Typography>
          </Fade>

        )
      }
    </Box>
  );
}
