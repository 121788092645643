import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


export default function IntervalSwitch(props) {
  const [value, setValue] = useState(props.value);
  const [toggle, setToggle] = useState(props.invert);


  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          onChange={(e) => {
            props.updateItemIntervals(props.time, props.action, e.currentTarget.checked);
            props.updateIntervalMap(props.time, props.order, props.action, e.currentTarget.checked);
            setValue(e.currentTarget.checked);
          }}
        />
      }
      label={props.label}
    />
  );
}
