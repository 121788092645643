import React, { useState } from 'react';

import Box from '@mui/material/Box';

import ItemSearch from './ItemSearch';
import PaletteToggle from './PaletteToggle';
import ItemPreview from './preview/ItemPreview';



export default function ItemSelect(props) {
  const [iid, setIid] = useState(props.item ? props.item.iid : '');
  const [item, setItem] = useState(props.item ? props.item.traits : {});

  const handleChange = (newIid) => {

    console.log('newIid', newIid);
    var pullItem = props.itemData[newIid];
    if(pullItem){
      setItem(pullItem);
      props.updateItem(props.order, 'iid', newIid);
      props.updateItem(props.order, 'traits', pullItem);
    }
  };

  const updatePalette = (newIid, newPalette) => {
    console.log('newPalette', newPalette);
    props.updateItem(props.order, 'palette', newPalette);
    if(newIid){
      handleChange(newIid);
    }
  }

  const handleSubmit = () => {
    var pullItem = props.itemData[iid];
    props.updateItem(props.order, 'iid', iid);
    props.updateItem(props.order, 'traits', pullItem);
    props.updateSelected(props.order);
  };

  return (
    <div>
      <ItemPreview item={props.item} itemState={item} length={200}/>
      <Box
        sx={{
          width: '100%',
          px: 0,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <PaletteToggle
          iid={iid}
          setIid={setIid}
          palette={props.item.palette}
          updatePalette={updatePalette}
        />
        <ItemSearch
          palette={props.item.palette}
          iid={iid}
          setIid={setIid}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          order={props.order}
        />
      </Box>
    </div>
  );
}
