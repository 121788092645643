import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route } from "react-router-dom";

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';

import Settings from './settings/Settings';
import Display from './display/Display';
import Info from './info/Info';

import FabNav from './utils/fabs/FabNav.js';

import { intervalArr, itemIntervalObj } from '../../../static/lab/objects';


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'drawerWidth' && prop !==  'open' })(
  ({ theme, open, drawerWidth }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
      width: window.innerWidth - drawerWidth,
    }),
  }),
);

export default function Controller(props) {
  const gridRef = useRef();

  // Window
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  window.addEventListener('resize', handleResize);

  // Drawers
  const [settings, setSettings] = useState(true);
  const [info, setInfo] = useState(false);
  const [step, setStep] = useState(0);

  // Grid properties
  const [gridState, setGridState] = useState({
    rows: 1,
    cols: 1,
    margin: 20,
    tileMargin: 5,
    colors: {
      dynamic: false,
      duration: '15s',
      angle: '315deg',
      colors: ['#ffffff'],
    },
  });

  // Items
  const [items, setItems] = useState([]);
  const [itemStates, setItemStates] = useState({invert: [], toggle: []});

  const [refresh, setRefresh] = useState(0);
  // Intervals
  const [intervals, setIntervals] = useState(intervalArr);
  const [intervalMap, setIntervalMap] = useState({});


  // Set items and itemStates on row, col, or intervalMap change
  useEffect(() => {
    // Get current state
    let numItems = gridState.rows * gridState.cols;
    let newItems = [];
    let newItemStates = {invert: [], toggle: [], bg: [], t: []};
    // Maintain existing items
    items.map((item, idx) => {
      if(item.iid !== '' && (idx+1)<=numItems){
        newItems.push(item);
        newItemStates.t.push(item.text);
        newItemStates.bg.push(item.background);
        newItemStates.invert.push(item.invert);
        newItemStates.toggle.push(false);
      }
    });
    // Append new empty items for remaining
    for(let i = newItems.length; i < numItems; i++){
      let newItem = {
        iid: '',
        verified: false,
        palette: 'P',
        textMultiple: 1,
        lineHeight: 1.1,
        text: true,
        background: true,
        invert: false,
        highlight: false,
        highlightObj: {color: '', angle: '0deg', intensity: '50%'},
        animation: '',
        animationObj: {type: 1, duration: '5s', shadow: false},
        traits: {},
        intervals: {...itemIntervalObj},
      };
      newItems.push(newItem);
      newItemStates.t.push(true);
      newItemStates.bg.push(true);
      newItemStates.invert.push(false);
      newItemStates.toggle.push(false);
    }
    // Set states
    setItemStates(newItemStates);
    setItems(newItems);
    console.log('newItems', newItems);
    console.log('gridState', gridState);
  }, [gridState.rows, gridState.cols, intervalMap, refresh]);


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Main ref={gridRef} open={settings} drawerWidth={width/2}>
        <Routes>
          <Route 
            path="/info*" 
            element={<Info step={step} setInfo={setInfo} settings={settings} width={settings ? width/2 : width} />} 
          />
          <Route 
            path='/'  
            element={
              <Display
                info={info}
                setInfo={setInfo}
                settings={settings}
                setSettings={setSettings}
                height={height}
                width={width}
                items={items}
                intervalMap={intervalMap}
                itemStates={itemStates}
                setItemStates={setItemStates}
                gridRef={gridRef}
                gridState={gridState}
              />
            } 
          />
        </Routes>
        <FabNav 
          rows={gridState.rows}
          cols={gridState.cols}
          info={info}
          setInfo={setInfo}
          setSettings={setSettings}
          gridRef={gridRef}
        />
      </Main>
      <Drawer
        sx={{
          width: width/2,
          flexShrink: 0,
        }}
        variant="persistent"
        anchor="right"
        open={settings}
      >
        <Settings
          gridRef={gridRef}
          step={step}
          setStep={setStep}
          width={width}
          gridState={gridState}
          setGridState={setGridState}
          setSettings={setSettings}
          setInfo={setInfo}
          info={info}
          items={items}
          setItems={setItems}
          intervals={intervals}
          intervalMap={intervalMap}
          setIntervalMap={setIntervalMap}
          setRefresh={setRefresh}
        />
      </Drawer>
    </Box>
  );
}
