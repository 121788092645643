import React from 'react';
import { NavLink } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export default function CollectionNav(props) {

  return (
    <Box
      sx={{
        width: 400,
        mr: 5,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {
        props.isms.slice(1).map((ism, ii) => (
          <Typography
            key={`collection-${ii}`}
            variant='h4'
            color='primary'
            component={NavLink}
            to={`/about/collections/${ism.iid}`}
            style={({ isActive }) => ({
              color: isActive ? '#545e6f' : '#d4d4d4',
              fontFamily: ism.font,
              textDecoration: 'none',
            })}
          >
            { ism.name }
          </Typography>
        ))
      }
    </Box>
  );
}
