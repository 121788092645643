import React from 'react';

import PropTypes from 'prop-types';


export default function SettingsTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-panel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      style={{
        width: props.width,
        height: 'calc(100vh - 48px)',
        padding: `0px 20px`,
        overflowY: 'auto',
      }}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

SettingsTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
