import React from 'react';

import RecordingFab from './RecordingFab';
import CaptureFab from './CaptureFab';
import InfoFab from './InfoFab';
import SettingsFab from './SettingsFab';


export default function FabNav(props) {

  return (
    <>
      <RecordingFab
        rows={props.rows}
        cols={props.cols}
        setInfo={props.setInfo}
      />
      <CaptureFab
        rows={props.rows}
        cols={props.cols}
        gridRef={props.gridRef}
      />
      <InfoFab
        rows={props.rows}
        cols={props.cols}
        info={props.info}
        setInfo={props.setInfo}
      />
      <SettingsFab setSettings={props.setSettings} />
  </>
  );
}
