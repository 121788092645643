import React, { useState } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import GridSettings from './sections/grid/GridSettings';
import ItemSettings from './sections/items/ItemSettings';
import Exports from './sections/exports/Exports';
import SettingsNav from './layout/nav/SettingsNav';
import SettingsTabPanel from './layout/containers/SettingsTabPanel';
import GridNav from './layout/nav/GridNav';

export default function Settings(props) {
  const [palette, setPalette] = useState('P');
  const [selected, setSelected] = useState(0);

  const handleChange = (event, newValue) => {
    props.setStep(newValue);
  };

  return (
    <div
      style={{
        width: props.width * 0.5,
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
      }}
    >
      <SettingsNav
        step={props.step}
        setStep={props.setStep}
        setSettings={props.setSettings}
        settings={props.settings}
        setInfo={props.setInfo}
        info={props.info}

      />
      <div
        style={{
          width: props.width * 0.5 - 50,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Tabs value={props.step} onChange={handleChange} sx={{width: '100%'}} centered>
          <Tab label="Grid" />
          <Tab label="Items" />
          <Tab label="Connect" />
        </Tabs>
        <GridNav 
          rows={props.gridState.rows}
          cols={props.gridState.cols}
          selected={selected}
          setSelected={setSelected}
        />
        <SettingsTabPanel value={props.step} index={0} width={props.width * 0.5 - 50}>
          <GridSettings
            gridState={props.gridState}
            setGridState={props.setGridState}
            width={props.width * 0.5 - 90}
          />
        </SettingsTabPanel>
        <SettingsTabPanel value={props.step} index={1} width={props.width * 0.5 - 50}>
          <ItemSettings
            rows={props.gridState.rows}
            cols={props.gridState.cols}
            selected={selected}
            setSelected={setSelected}
            items={props.items}
            setItems={props.setItems}
            intervals={props.intervals}
            intervalMap={props.intervalMap}
            setIntervalMap={props.setIntervalMap}
            setRefresh={props.setRefresh}
            palette={palette}
            setPalette={setPalette}
            width={props.width * 0.5 - 90}
          />
        </SettingsTabPanel>
        <SettingsTabPanel value={props.step} index={2} width={props.width * 0.5 - 50}>
          <Exports
            gridRef={props.gridRef}
          />
        </SettingsTabPanel>
      </div>
    </div>
  );
}
