import React from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import fontData from '../../../../../static/fonts/fonts_list.js';


export default function Advanced(props) {
  const fonts = fontData;

  const pastelColors = ["","#fbf8cc","#fde4cf","#ffcfd2","#f1c0e8","#cfbaf0","#a3c4f3","#90dbf4","#8eecf5","#98f5e1","#b9fbc0"];
  const slateColors = ["","#9da5ac","#adb5bd","#bbbfc4","#c8c9ca","#d5d3d0","#e2ddd6","#e8e5e0","#edede9","#e3d5ca","#d5bdaf"];


  let filter = props.filter;
  let updateFilter = props.updateFilter;

  const colors = filter.palette == 'pastel' ? pastelColors : slateColors;

  const phrases = props.isms.find(i => i.iid == filter.set).phrases;


  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FormControl  variant="standard" sx={{ m: 1, minWidth: 300 }}>
        <InputLabel id="font-label">Font</InputLabel>
        <Select
          labelId="font-label"
          id="font-select"
          value={filter.font}
          label="Font"
          onChange={(e, newValue) => updateFilter('font',e.target.value)}
          style={{
            color: '#545e6f',
            fontSize: 32,
            height: 65,
          }}
        >
          {
            fonts.map((font, iid) => (
              <MenuItem
                key={`font-option-${iid}`}
                value={font[1]}
                style={{fontFamily: font[1], color: '#545e6f',fontSize: 24,}}
              >
                {font[1]}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl  variant="standard" sx={{ m: 1, minWidth: 300 }}>
        <InputLabel id="bgc-label">Background Color</InputLabel>
        <Select
          labelId="bgc-label"
          id="bgc-select"
          value={filter.backgroundColor}
          label="Background Color"
          onChange={(e, newValue) => updateFilter('backgroundColor',e.target.value)}
          style={{
            color: '#545e6f',
            fontSize: 32,
            height: 65,
          }}
        >
          {
            colors.map((color, cid) => (
              <MenuItem
                key={`bgc-option-${cid}`}
                value={color}
                style={{color: color,fontSize: 24}}
              >
                {color}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl  variant="standard" sx={{ m: 1, minWidth: 300 }}>
        <InputLabel id="tc-label">Text Color</InputLabel>
        <Select
          labelId="tc-label"
          id="tc-select"
          value={filter.textColor}
          label="Text Color"
          onChange={(e, newValue) => updateFilter('textColor',e.target.value)}
          style={{
            color: '#545e6f',
            fontSize: 32,
            height: 65,
          }}
        >
          {
            colors.map((color, tcid) => (
              <MenuItem
                key={`tc-option-${tcid}`}
                value={color}
                style={{color: color,fontSize: 24,}}
              >
                {color}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl  variant="standard" sx={{ m: 1, width: '100%', minWidth: 300, maxWidth: 930 }}>
        <InputLabel id="phrase-label">Phrase</InputLabel>
        <Select
          labelId="phrase-label"
          id="phrase-select"
          value={filter.phrase}
          label="Phrase"
          onChange={(e, newValue) => updateFilter('phrase',e.target.value)}
          style={{
            color: '#545e6f',
            fontSize: 32,
            height: 65,
          }}
        >
          {
            phrases.map((phrase, phid) => (
              <MenuItem
                key={`phrase-option-${phid}`}
                value={phrase}
                style={{fontSize: 24,}}
              >
                {phrase}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Box>
  );
}
