import React, { useEffect, useState } from 'react';

import SettingsSection from '../../layout/containers/SettingsSection';
import Items from '../../layout/containers/Items';

import ItemConfig from './item-config/ItemConfig';
import ItemSelect from './item-select/ItemSelect';
import Appearance from './item-config/appearance/Appearance';
import IntervalConfig from './item-config/interval/IntervalConfig';


const ismsData = require('../../../../../../static/lab/properties/isms.json');


export default function ItemSettings(props) {
  const [palette, setPalette] = useState('P');

  const updateItem = (order, key, value) => {
    let newItems = [...props.items];
    newItems[order][key] = value;
    props.setItems(newItems);
    props.setRefresh(prev => prev + 1);
  };

  const updateSelected = (changedItem) => {
    // Set new selected
    let tempArray = props.items.slice(changedItem);
    let beginning = props.items.slice(0, changedItem);
    tempArray.push(...beginning);
    let empty = false;
    for (var idx = 0; idx < tempArray.length; idx++) {
      if(tempArray[idx].iid == ''){
        empty = true;
        let iindex = changedItem + idx < props.items.length ? changedItem + idx : changedItem + idx - props.items.length;
        props.setSelected(iindex);
        break;
      }
    }
    if(!empty){
      props.setSelected(0);
    }
  }

  const updateIntervalMap = (interval, order, action, val) => {
    let newIM = {...props.intervalMap};
    // Removal
    if(val == false){
      if(newIM[interval][action].length === 1){
        delete newIM[interval][action];
      }
      if(Object.keys(newIM[interval]).length === 0){
        delete newIM[interval];
      }
    } else {
      // Set new selected
      if(interval in newIM){
        if(action in newIM[interval]){
          newIM[interval][action].push(order);
        } else {
          newIM[interval][action] = [order];
        }
      } else {
        let newObj = {};
        newObj[action] = [order];
        newIM[interval] = newObj;
      }
    }
    props.setIntervalMap(newIM);
  }

  return (
    <div
      style={{
        width: '100%',
        minWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Items key={`item-config-${props.selected}`} selected={props.selected}>
        <SettingsSection title='Item Select' width={props.width}>
          <ItemSelect
            itemData={ismsData}
            item={props.items[props.selected]}
            order={props.selected}
            updateItem={updateItem}
            updateSelected={updateSelected}
            palette={palette}
            setPalette={setPalette}
          />
        </SettingsSection>

        <ItemConfig>
          <SettingsSection title='Appearance' width={props.width-10}>
            <Appearance
              item={props.items[props.selected]}
              invert={props.items[props.selected].invert}
              order={props.selected}
              updateItem={updateItem}
            />
          </SettingsSection>
          <SettingsSection title='Intervals' width={props.width-10}>
            <IntervalConfig
              item={props.items[props.selected]}
              invert={props.items[props.selected].invert}
              order={props.selected}
              updateItem={updateItem}
              updateIntervalMap={updateIntervalMap}
              intervals={props.intervals}
            />
          </SettingsSection>
        </ItemConfig>
      </Items>
    </div>
  );
}
