export default [
  [
    "Bangers.ttf",
    "Bangers",
    "regular",
    1
  ],
  [
    "NovaMono.ttf",
    "Nova Mono",
    "regular",
    1
  ],
  [
    "Cinzel-Bold.ttf",
    "Cinzel",
    "bold",
    0.9
  ],
  [
    "SpecialElite.ttf",
    "Special Elite",
    "regular",
    1
  ],
  [
    "MavenPro.ttf",
    "Maven Pro",
    "bold",
    1
  ],
  [
    "AbrilFatface.ttf",
    "Abril Fatface",
    "regular",
    1
  ],
  [
    "RubikGlitch.ttf",
    "Rubik Glitch",
    "regular",
    1
  ],
  [
    "SendFlowers.ttf",
    "Send Flowers",
    "regular",
    1
  ],
  [
    "NovaMono.ttf",
    "Nova Mono",
    "regular",
    1
  ],
  [
    "BlackOpsOne.ttf",
    "Black Ops One",
    "regular",
    1
  ],
  [
    "FlowRounded.ttf",
    "Flow Rounded",
    "regular",
    1
  ],
  [
    "Galada.ttf",
    "Galada",
    "regular",
    1
  ],
  [
    "LibreBarcode39Text.ttf",
    "Libre Barcode 39 Text",
    "regular",
    1
  ],
  [
    "Plaster.ttf",
    "Plaster",
    "regular",
    0.8
  ],
  [
    "RubikWetPaint.ttf",
    "Rubik Wet Paint",
    "regular",
    1
  ],
  [
    "Audiowide.ttf",
    "Audiowide",
    "regular",
    1
  ],
  [
    "RacingSansOne.ttf",
    "Racing Sans One",
    "regular",
    1
  ],
  [
    "FascinateInline.ttf",
    "Fascinate Inline",
    "regular",
    1
  ],
  [
    "UnifrakturMaguntia.ttf",
    "UnifrakturMaguntia",
    "regular",
    1
  ],
  [
    "SonsieOne.ttf",
    "Sonsie One",
    "regular",
    1
  ],
  [
    "ZillaSlabHighlight.ttf",
    "Zilla Slab Highlight",
    "bold",
    1
  ],
  [
    "RedactedScript.ttf",
    "Redacted Script",
    "bold",
    1
  ],
  [
    "Oi.ttf",
    "Oi",
    "regular",
    0.7
  ],
  [
    "PaletteMosaic.ttf",
    "Palette Mosaic",
    "regular",
    1
  ]
]
