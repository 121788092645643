import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { exportComponentAsPNG } from 'react-component-export-image';


export default function CaptureFab(props) {

  return (
    <Tooltip title="Capture" placement={props.rows >= props.cols ? "left" : 'top'}>
      <IconButton
        aria-label='capture'
        sx={{
          position: 'absolute',
          bottom: 'calc(50% - 20px)',
          right: props.rows >= props.cols ? 16 : 104,
          bottom: props.rows >= props.cols ? 104 : 16,
          color: '#d3d3d3',
        }}
        color='inherit'
        onClick={() => exportComponentAsPNG(props.gridRef)}
      >
        <AspectRatioIcon />
      </IconButton>
    </Tooltip>
  );
}
