import * as React from 'react';

import { NavLink } from "react-router-dom";

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarBorder from '@mui/icons-material/StarBorder';

import InfoIcon from '@mui/icons-material/Info';
import StartIcon from '@mui/icons-material/Start';
import SettingsIcon from '@mui/icons-material/Settings';
import GridViewIcon from '@mui/icons-material/GridView';
import CropSquareIcon from '@mui/icons-material/CropSquare';

import logo from '../../../../../static/lab/images/logo.png';

export default function InfoMenu(props) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box 
      sx={{
        width: 200,
        bgcolor: 'background.paper',
      }}
    >
      <Box 
        sx={{
          width: '100%',
          px: '16px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <img src={logo} width='24px' alt="logo" style={{marginRight:32 }}/>
        <div>
          isms display
        </div>
      </Box>
      <List
        component="nav"
        aria-labelledby="info-menu"
      >
        <ListItemButton
          component={NavLink}
          to='/info'
          exact={true}
          style={({ isActive }) => ({
            color: isActive ? '#ADD8E6' : '#383838',
          })}
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="Intro" />
        </ListItemButton>
        <ListItemButton
          component={NavLink}
          to='/info/getting-started'
          style={({ isActive }) => ({
            color: isActive ? '#ADD8E6' : '#383838',
          })}
        >
          <ListItemIcon>
            <StartIcon />
          </ListItemIcon>
          <ListItemText primary="Getting Started" />
        </ListItemButton>
        <ListItemButton 
          component={NavLink}
          to='/info/grid'
          onClick={handleClick}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItemButton>
        <List component="div" disablePadding>
          <ListItemButton 
            component={NavLink}
            to='/info/grid'
            sx={{ pl: 4 }}
            style={({ isActive }) => ({
              color: isActive ? '#ADD8E6' : '#383838',
            })}
          >
            <ListItemIcon color='secondary'>
              <GridViewIcon />
            </ListItemIcon>
            <ListItemText primary="Grid" />
          </ListItemButton>
          <ListItemButton 
            component={NavLink}
            to='/info/items'
            sx={{ pl: 4 }}
            style={({ isActive }) => ({
              color: isActive ? '#ADD8E6' : '#383838',
            })}
          >
            <ListItemIcon color='secondary'>
              <CropSquareIcon />
            </ListItemIcon>
            <ListItemText primary="Items" />
          </ListItemButton>
          <ListItemButton 
            component={NavLink}
            to='/info/connect'
            sx={{ pl: 4 }}
            style={({ isActive }) => ({
              color: isActive ? '#ADD8E6' : '#383838',
            })}
          >
            <ListItemIcon color='secondary'>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Connect" />
          </ListItemButton>
        </List>
      </List>
    </Box>
    
  );
}
