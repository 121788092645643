import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';



export default function GridNav(props) {
  const height = 40*props.rows+20;
  const width = 40*props.cols+20;

  const borderpx = height > width ? width/70+2 : height/70+2;
  const borderrd = height > width ? width/5+20 : height/5+20;

  const Div1 = styled('div', { shouldForwardProp: (prop) => prop !== 'length' })(
    ({ theme, length }) => ({
      position:'absolute',
      top:31,
      left:`calc(50% - ${(width)/2}px)`,
      height:`${height}px`,
      width:`${width}px`,
      backgroundColor:'white',
      border:`${borderpx}px solid rgba(0, 0, 0, 0.08)`,
    }),
  ); 

  const Div2 = styled('div', { shouldForwardProp: (prop) => prop !== 'length' })(
    ({ theme, length }) => ({
      position:'relative',
      top:0,
      left:0,
      height:`${height+2}px`,
      width:`${width+2}px`,
      backgroundColor:'white',
      borderRadius: `${borderrd}px`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  );




  return (
    <Box
      sx={{
        flexGrow: 1,
        width: '100%',
        p: '30px 0px 10px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 10px 10px #ffffff',
        zIndex: 3,
        position: 'relative',
      }}
    >
      <Div1 />
      <Div2>
        {
          [...Array(props.rows)].map((e, i) => (
            <div
              key={`row-${i+1}`}
              style={{
                display: 'flex',
              }}
            >
              {
                [...Array(props.cols)].map((ev, j) => (
                  <div
                    key={(j+1) + '-' + (i+1)}
                    style={{
                      border: props.selected === (props.cols*i)+j ? '1px solid #ADD8E6' : '1px solid #545e6f',
                      background:  props.selected === (props.cols*i)+j ? '#ADD8E654' : '',
                      width: 36,
                      height: 36,
                      margin: 2,
                    }}
                    onClick={() => {
                      props.setSelected((props.cols*i)+j);
                    }}
                  />
                ))
              }
            </div>
          ))
        }
      </Div2>
    </Box>
  );
}
