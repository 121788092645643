import React from 'react';
import { makeStyles } from '@mui/styles';
import { useParams, useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';

import IsmTile from './IsmTile';
import IsmInfo from './IsmInfo';

const useStyles = makeStyles((theme) => ({
  ismRoot: {
    width: '100%',
    padding: '40px 10%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function Ism(props) {
  const classes = useStyles();
  let { id } = useParams();

  let navigate = useNavigate();
  function handleClick() {
    navigate("/");
  }

  const ismsData = require('../../../../static/properties/isms.json');

  console.log('ismsData', ismsData[id]);

  const ismData = ismsData[id];
  return (
    <div className={classes.ismRoot}>
      <Typography
        variant='h2'
        color='secondary'
        style={{color: '#545e6f', fontFamily: 'Spartan', marginBottom: 40}}
      >
        { '#' + id }
      </Typography>
      <IsmTile data={ismData} width={400} />
      <IsmInfo data={ismData}/>
    </div>
  );
}
