import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import GridViewIcon from '@mui/icons-material/GridView';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoIcon from '@mui/icons-material/Info';


export default function Display(props) {

  return (
    <Box 
      sx={{
        width: 50,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRight: '1px solid #d3d3d3',
        background: '#00000014',
      }}
    >
      <Box
        sx={{
          width: 50,
          textAlign: 'center',
          color: '#ADD8E6',
          fontSize: 24,
          padding: `${10}px`,
        }}
      >
        s<br/>e<br/>t<br/>t<br/>i<br/>n<br/>g<br/>s
      </Box>
      <Box 
        sx={{
          width: 50,
          padding: '5px',
        }}
      >
        <Tooltip title="Grid" placement="left">
          <IconButton
            aria-label='grid'
            color='inherit'
            onClick={() => props.setStep(0)}
            style={{
              color: props.step == 0 ? '#ADD8E6' : '#d3d3d3',
            }}
          >
            <GridViewIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Items" placement="left">
          <IconButton
            aria-label='items'
            color='inherit'
            onClick={() => props.setStep(1)}
            style={{
              color: props.step == 1 ? '#ADD8E6' : '#d3d3d3',
            }}
          >
            <CropSquareIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Info" placement="left">
          <IconButton
            aria-label='info'
            color='inherit'
            onClick={() => props.setInfo(prev => !prev)}
            style={{
              color: props.info ? '#ADD8E6' : '#d3d3d3',
            }}
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={!props.settings ? 'Close settings' : 'Open settings'} placement="left">
          <IconButton
            aria-label='toggle settings'
            color='inherit'
            onClick={() => props.setSettings(prev => !prev)}
            style={{
              color: '#545e6f',
            }}
          >
            {
              !props.settings ? (
                <ArrowForwardIosIcon />
              ) : (
                <ArrowBackIosNewIcon />
              )
            }
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}
