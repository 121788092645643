import React from 'react';

import Box from '@mui/material/Box';


export default function Palettes(props) {

  const width = window.innerWidth < 700 ? (window.innerWidth-80)/10 : 60;

  const swatches = [
    {
      name: 'Pastels',
      colors: ["#fbf8cc","#fde4cf","#ffcfd2","#f1c0e8","#cfbaf0","#a3c4f3","#90dbf4","#8eecf5","#98f5e1","#b9fbc0"],
    },
    {
      name: 'Slates',
      colors: ["#9da5ac","#adb5bd","#bbbfc4","#c8c9ca","#d5d3d0","#e2ddd6","#e8e5e0","#edede9","#e3d5ca","#d5bdaf"],
    },
    {
      name: 'Coral',
      colors: ["#ddf8e8", "#d5e7dd", "#cdd5d1", "#c1bebe", "#b4a6ab", "#a48a97", "#9c7c8d", "#946e83", "#7b5f6c", "#615055"],
    },

  ];
  // {
  //   name: 'Forest',
  //   colors: ["#FA99FF", "#7353BA", "#2F195F", "#0F1020", "#7F6A8B", "#EFC3F5", "#C9B8C3", "#A2AD91", "#A6A087", "#A9927D"],
  // },

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mb: '20px',
    }}>
      <h3 style={{width:'100%',textAlign:'center'}}>3 Palettes</h3>
      {
        swatches.map((swatch, si) => (
          <div key={swatch.name} >
            <div
              style={{
                color: '#c5c5c5',
                marginBottom: 5,
              }}
            >
              { swatch.name }
            </div>
            <div
              style={{
                width: '100%',
                height: 60,
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'no-wrap',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              {
                swatch.colors.map((color, ci) => (
                  <div
                    key={`${swatch.name}-${ci}`}
                    style={{backgroundColor: color}}
                    style={{
                      backgroundColor: color,
                      width: width,
                      height: width,
                    }}
                  >
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
    </Box>
  );
}
