import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import Item from './Item';

export default function DisplayGrid(props) {
  const [margin, setMargin] = useState(props.margin);

  var horizontalDivide = (props.width - 1 - (2 * props.margin)) / props.cols;
  var verticalDivide = (props.height - 1 - (2 * props.margin)) / props.rows;
  var length = horizontalDivide >= verticalDivide ? verticalDivide : horizontalDivide;

  useEffect(() => {
    setMargin(props.margin);
  }, [props.margin]);

  useEffect(() => {
    let newIntervalObjects = [];
    for (const [time, obj] of Object.entries(props.intervalMap)) {
      let newInterval = setInterval(() => {
        let newItemStates = {...props.itemStates};
        for (const [action, arr] of Object.entries(obj)) {
          let newActionStates = newItemStates[action];
          arr.map(order => {
            newActionStates[order] = !newActionStates[order];
          });
          newItemStates[action] = newActionStates;
        };
        props.setItemStates(newItemStates);
      }, time);
      newIntervalObjects.push(newInterval);
    }
    return () => {
      newIntervalObjects.map(io => {
        clearInterval(io);
      })
    }
  }, [props.intervalMap, props.itemStates]);

  return (
    <div>
      <Box
        sx={{
          width: `calc(100% - ${2*props.margin})`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: `${props.margin}px`,
        }}
      >
        {
          props.items.length == props.rows * props.cols && (
            [...Array(props.rows)].map((e, i) => (
              <Box 
                key={`row-${i+1}`} 
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                }}
              >
                {
                  [...Array(props.cols)].map((ev, j) => (
                    <Item
                      key={props.items[(props.cols*i)+j].iid + '-' + (j+1) + '-' + (i+1)}
                      order={(props.cols*i)+j}
                      item={props.items[(props.cols*i)+j] ? props.items[(props.cols*i)+j] : ''}
                      margin={props.tileMargin}
                      length={length}
                      t={props.itemStates.t[(props.cols*i)+j]}
                      bg={props.itemStates.bg[(props.cols*i)+j]}
                      invert={props.itemStates.invert[(props.cols*i)+j]}
                      toggle={props.itemStates.toggle[(props.cols*i)+j]}
                    />
                  ))
                }
              </Box>
            ))
          )
        }
      </Box>
    </div>
  );
}
