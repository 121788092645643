import React from 'react';
import { styled } from '@mui/material/styles';


export const Tile = styled('div', { shouldForwardProp: (prop) => prop !== ('width' ||'widthDivider' ||  'textColor' || 'backgroundColor' || 'fontFamily') })(
  ({ theme, width, widthDivider, textColor, backgroundColor, fontFamily }) => ({
    display: 'flex',
    margin: 2,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: width,
    height: width,
    paddingLeft: width * 0.05,
    paddingRight: width * 0.05,
    fontSize: width * (126/1000),
    lineHeight: `${width * (132/1000)}px`,
    color: textColor,
    backgroundColor: backgroundColor,
    fontFamily: fontFamily,
    "&:hover": {
      backgroundColor: textColor,
      color: backgroundColor,
      cursor: 'pointer',
    },
  }),
);
