import React from 'react';

export default function ItemConfig(props) {

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      { props.children }
    </div>
  );
}
