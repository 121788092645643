import React, { useState } from 'react';

import Box from '@mui/material/Box';

import ToggleGroup from '../../../layout/inputs/ToggleGroup';
import AnimateConfig from './AnimateConfig';
import ColorPicker from './ColorPicker';


export default function ColorSettings(props) {
  const [dynamic, setDynamic] = useState(props.displayColors.dynamic);

  const dynamicOptions = [
    {
      name: 'static',
      value: false,
    },
    {
      name: 'dynamic',
      value: true,
    },
  ];

  const updateBGC = (type, val) => {
    let newObj = {...props.displayColors};
    newObj[type] = val;
    props.updateGridState('colors', newObj);
  }

  return (
    <Box
      id='color-settings'
      sx={{ width: '100%' }}
    >
      <ToggleGroup
        label='Animation'
        options={dynamicOptions}
        type='dynamic'
        updateValue={updateBGC}
        value={dynamic}
        setValue={setDynamic}
      />
      {
        dynamic && (
          <AnimateConfig
            angle={props.displayColors.angle}
            duration={props.displayColors.duration}
            updateValue={updateBGC}
          />
        )
      }
      <ColorPicker
        displayColors={props.displayColors}
        updateGridState={props.updateGridState}
        width={props.width} />
    </Box>
  );
}
