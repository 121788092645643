import React, { useEffect, useState } from 'react';
import './App.css';
import theme from './Theme';

import { makeStyles } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';

import WebFont from 'webfontloader';

import Site from './site/Site.js';
import Lab from './lab/Lab.js';


const useStyles = makeStyles((theme) => ({
  containerRoot: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
}));





function App() {

  const classes = useStyles();

  useEffect(() => {
   WebFont.load({
     google: {
      families: [
        'Abril Fatface',
        'Audiowide',
        'Barrio',
        'Bangers',
        'Black Ops One',
        'Blaka',
        'Bungee',
        'Bungee Inline',
        'Bungee Shade',
        'Caesar Dressing',
        'Cinzel',
        'Codystar',
        'Emblema One',
        'Fascinate Inline',
        'Faster One',
        'Flow Block',
        'Flow Circular',
        'Flow Rounded',
        'Fugaz One',
        'Fredericka the Great',
        'Geostar',
        'Geostar Fill',
        'Galada',
        'Iceberg',
        'Lacquer',
        'Kumar One',
        'Lily Script One',
        'Libre Barcode 39 Text',
        'Maven Pro',
        'Metal Mania',
        'Monofett',
        'Monoton',
        'Nosifer',
        'Nova Mono',
        'Nova Square',
        'Oi',
        'Orbitron',
        'Pacifico',
        'Palette Mosaic',
        'Plaster',
        'Poller One',
        'Reggae One',
        'Righteous',
        'Rock 3D',
        'Rusian Display',
        'Racing Sans One',
        'Redacted Script',
        'Rubik Glitch',
        'Rubik Microbe',
        'Rubik Moonrocks',
        'Rubik Wet Paint',
        'Sail',
        'Send Flowers',
        'Shojumaru',
        'Shrikhand',
        'Slackey',
        'Sirin Stencil',
        'Sonsie One',
        'Spartan',
        'Special Elite',
        'Uncial Antiqua',
        'UnifrakturMaguntia',
        'Zilla Slab Highlight',
       ]
     }
   });
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(window.innerWidth > 800 ? false : true);
  // Window resizing
  const handleResize = () => {
    setWidth(window.innerWidth);
    setMobile(window.innerWidth > 800 ? false : true);
};

  window.addEventListener('resize', handleResize);


  return (
    <ThemeProvider theme={theme}>
      <div className={classes.containerRoot}>
        {
          window.location.host.split(".")[0] == "lab" ? (
            <Lab width={width} mobile={mobile} />
          ) : (
            <Site width={width} mobile={mobile} />
          )
        }
      </div>
    </ThemeProvider>
  );
}


export default App;
