import React, { useState } from 'react';
import _, { map } from 'underscore';

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';

import { Tile } from '../utils/Tile';

const ismsData = require('../../../../../static/properties/isms.json');

const HeroTile = styled('div', { shouldForwardProp: (prop) => prop !== ('width' || 'textColor' || 'backgroundColor' || 'fontFamily') })(
  ({ theme, width, textColor, backgroundColor, fontFamily }) => ({
    display: 'flex',
    margin: 2,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: width,
    height: width,
    paddingLeft: width * 0.05,
    paddingRight: width * 0.05,
    fontSize: width * (126/1000),
    lineHeight: `${width * (132/1000)}px`,
    color: textColor,
    backgroundColor: backgroundColor,
    fontFamily: fontFamily,
    "&:hover": {
      backgroundColor: textColor,
      color: backgroundColor,
      cursor: 'pointer',
    },
  }),
);

export default function Hero(props) {
  const ismIds = Object.keys(ismsData);

  const palletIsmIds = ismIds.filter(id => id.startsWith(props.palette));

  const randomSet = _.sample(palletIsmIds, 16);

  // 'calc(100vw - (100vw - 100%))'

  return (
    <Box
      sx={{
        width: '100vw',
        marginBottom: 2,
      }}
    >
      <Box
        sx={{
          width: '100vw',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {
          randomSet.map((ismId, ii) => (
            <Tile
              width={props.mobile ? props.width/4-4 : props.width/8-4}
              widthDivider={props.mobile ? 4 : 8}
              textColor={ismsData[ismId].textColor}
              backgroundColor={ismsData[ismId].backgroundColor}
              fontFamily={ismsData[ismId].font}
            >
              { ismsData[ismId].phrase }
            </Tile>
          ))
        }
      </Box>
    </Box>
  );
}
