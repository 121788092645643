import React, { useState } from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function AnimateConfig(props) {
  const [angle, setAngle] = useState(props.angle);
  const [duration, setDuration] = useState(props.duration);

  const handleAnimateChange = (type, stateFxn, val) => {
    stateFxn(val);
    props.updateValue(type, val);
  };

  const angleOptions = [0,30,45,60,90,120,135,150,180,210,225,240,270,300,315,330];
  const timeOptions = ['5s','10s','15s','30s','60s'];

  return (
    <div style={{ width: '100%', marginBottom: 20, }}>
      <FormControl variant="standard" sx={{ mx: 1, width: 'calc(50% - 16px)' }}>
        <InputLabel id="animate-angle-label">Angle</InputLabel>
        <Select
          labelId="animate-angle-label"
          id="animate-angle-select"
          value={angle}
          onChange={(e) => handleAnimateChange('angle', setAngle, e.target.value)}
          label="Angle"
        >
          {
            angleOptions.map(ang => (
              <MenuItem key={`${ang}deg`} value={`${ang}deg`}>{`${ang}\u00b0`}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl variant="standard" sx={{ mx: 1, width: 'calc(50% - 16px)'}}>
        <InputLabel id="animate-intensity-label">Duration</InputLabel>
        <Select
          labelId="animate-duration-label"
          id="animate-duration-select"
          value={duration}
          onChange={(e) => handleAnimateChange('duration', setDuration, e.target.value)}
          label="Duration"
        >
          {
            timeOptions.map(time => (
              <MenuItem key={time} value={time}>{time}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </div>
  );
}
