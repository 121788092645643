import React from 'react';
import { styled } from '@mui/material/styles';


export const InfoRow = styled('div', { shouldForwardProp: (prop) => prop !== 'width' })(
  ({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  }),
);
