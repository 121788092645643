import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { makeStyles } from '@mui/styles';

import Nav from './layout/nav/Nav';
import Home from './pages/home/Home';
import Gallery from './pages/gallery/Gallery';
import Ism from './pages/ism/Ism';
import About from './pages/about/About';
import LabPage from './pages/lab/LabPage';
import Mint from './pages/mint/Mint';

import ismsData from '../../static/properties/isms_list.json';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '1200px',
  },
}));

const phrases = require('../../static/properties/sets_dict.json')

const isms = [
  {
    name: 'All',
    iid: 'all',
    pastels: require.context('../../static/images/isms/pastels/', true, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/', true, /\.(png)$/),
    phrases: [],
    font: 'Spartan',
    fontColor: '#f1c0e8',
    backgroundColor: '#cfbaf0',
  },
  {
    name: 'Absurdism',
    iid: 'absurdism',
    pastels: require.context('../../static/images/isms/pastels/absurdism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/absurdism/', false, /\.(png)$/),
    phrases: phrases['absurdism'],
    font: 'Bangers',
    fontColor: '#f1c0e8',
    backgroundColor: '#cfbaf0',
  },
  {
    name: 'Altruism',
    iid: 'altruism',
    pastels: require.context('../../static/images/isms/pastels/altruism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/altruism/', false, /\.(png)$/),
    phrases: phrases['altruism'],
    font: 'Nova Mono',
    fontColor: '#8eecf5',
    backgroundColor: '#fde4cf',
  },
  {
    name: 'Collectivism',
    iid: 'collectivism',
    pastels: require.context('../../static/images/isms/pastels/altruism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/altruism/', false, /\.(png)$/),
    phrases: phrases['collectivism'],
    font: 'Nova Mono',
    fontColor: '#8eecf5',
    backgroundColor: '#fde4cf',
  },
  {
    name: 'Cynicism',
    iid: 'cynicism',
    pastels: require.context('../../static/images/isms/pastels/cynicism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/cynicism/', false, /\.(png)$/),
    phrases: phrases['cynicism'],
    font: 'Cinzel',
    fontColor: '#f1c0e8',
    backgroundColor: '#a3c4f3',
  },
  {
    name: 'Existentialism',
    iid: 'existentialism',
    pastels: require.context('../../static/images/isms/pastels/existentialism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/existentialism/', false, /\.(png)$/),
    phrases: phrases['existentialism'],
    font: 'Special Elite',
    fontColor: '#a3c4f3',
    backgroundColor: '#8eecf5',
  },
  {
    name: 'Individualism',
    iid: 'individualism',
    pastels: require.context('../../static/images/isms/pastels/existentialism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/existentialism/', false, /\.(png)$/),
    phrases: phrases['individualism'],
    font: 'Special Elite',
    fontColor: '#a3c4f3',
    backgroundColor: '#8eecf5',
  },
  {
    name: 'Materialism',
    iid: 'materialism',
    pastels: require.context('../../static/images/isms/pastels/minimalism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/minimalism/', false, /\.(png)$/),
    phrases: phrases['materialism'],
    font: 'Maven Pro',
    fontColor: '#ffcfd2',
    backgroundColor: '#98f5e1',
  },
  {
    name: 'Minimalism',
    iid: 'minimalism',
    pastels: require.context('../../static/images/isms/pastels/minimalism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/minimalism/', false, /\.(png)$/),
    phrases: phrases['minimalism'],
    font: 'Maven Pro',
    fontColor: '#ffcfd2',
    backgroundColor: '#98f5e1',
  },
  {
    name: 'Modernism',
    iid: 'modernism',
    pastels: require.context('../../static/images/isms/pastels/narcissism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/narcissism/', false, /\.(png)$/),
    phrases: phrases['modernism'],
    font: 'Abril Fatface',
    fontColor: '#fde4cf',
    backgroundColor: '#b9fbc0',
  },
  {
    name: 'Narcissism',
    iid: 'narcissism',
    pastels: require.context('../../static/images/isms/pastels/narcissism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/narcissism/', false, /\.(png)$/),
    phrases: phrases['narcissism'],
    font: 'Abril Fatface',
    fontColor: '#fde4cf',
    backgroundColor: '#b9fbc0',
  },
  {
    name: 'Nihilism',
    iid: 'nihilism',
    pastels: require.context('../../static/images/isms/pastels/nihilism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/nihilism/', false, /\.(png)$/),
    phrases: phrases['nihilism'],
    font: 'Rubik Glitch',
    fontColor: '#f1c0e8',
    backgroundColor: '#fbf8cc',
  },
  {
    name: 'Optimism',
    iid: 'optimism',
    pastels: require.context('../../static/images/isms/pastels/optimism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/optimism/', false, /\.(png)$/),
    phrases: phrases['optimism'],
    font: 'Send Flowers',
    fontColor: '#b9fbc0',
    backgroundColor: '#90dbf4',
  },
  {
    name: 'Pessimism',
    iid: 'pessimism',
    pastels: require.context('../../static/images/isms/pastels/nihilism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/nihilism/', false, /\.(png)$/),
    phrases: phrases['pessimism'],
    font: 'Rubik Glitch',
    fontColor: '#f1c0e8',
    backgroundColor: '#fbf8cc',
  },
  {
    name: 'Romanticism',
    iid: 'romanticism',
    pastels: require.context('../../static/images/isms/pastels/stoicism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/stoicism/', false, /\.(png)$/),
    phrases: phrases['romanticism'],
    font: 'Nova Mono',
    fontColor: '#98f5e1',
    backgroundColor: '#ffcfd2',
  },
  {
    name: 'Stoicism',
    iid: 'stoicism',
    pastels: require.context('../../static/images/isms/pastels/stoicism/', false, /\.(png)$/),
    slates: require.context('../../static/images/isms/slates/stoicism/', false, /\.(png)$/),
    phrases: phrases['stoicism'],
    font: 'Nova Mono',
    fontColor: '#98f5e1',
    backgroundColor: '#ffcfd2',
  },
];



function Site(props) {

  const classes = useStyles();

  useEffect(() => {

  }, []);


  return (
    <Router>
      <div className={classes.container}>
        <Nav mobile={props.mobile} />
        <Routes>
          <Route element={<LabPage />} path="/lab" />
          <Route element={<Home ismsData={ismsData} mobile={props.mobile} isms={isms} />} path='/'  />
          <Route element={<About isms={isms} />} path='/about/*' />
          <Route element={<Mint />} path='/mint/*' />
          <Route element={<Gallery ismsData={ismsData} isms={isms} />} path={`/gallery/:type`} />
          <Route element={<Gallery ismsData={ismsData} isms={isms} />} path={`/gallery`} />
          <Route element={<Ism />} path={`/ism/:id`} />
        </Routes>
      </div>
    </Router>
  );
}


export default Site;
