import React from 'react';

import SettingsSection from '../../layout/containers/SettingsSection';
import LayoutSettings from './layout/LayoutSettings';
import ColorSettings from './colors/ColorSettings';


export default function GridSettings(props) {

  const updateGridState = (name, value) => {
    props.setGridState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  return (
    <div style={{width: '100%'}}>
      <SettingsSection title='Layout' width={props.width}>
        <LayoutSettings
          width={props.width-20}
          gridState={props.gridState}
          updateGridState={updateGridState}
          rows={props.gridState.rows}
          cols={props.gridState.cols}
          margin={props.gridState.margin}
          tileMargin={props.gridState.tileMargin}
        />
      </SettingsSection>
      <SettingsSection title='Colors' width={props.width}>
        <ColorSettings
          width={props.width-20}
          updateGridState={updateGridState}
          displayColors={props.gridState.colors}
        />
      </SettingsSection>
    </div>
  );
}
