import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Demo from './demo/Demo';



export default function LabPage(props) {

  let navigate = useNavigate();
  function handleClick() {
    navigate("/");
  }

  useEffect(() => {

  }, []);

  console.log('path',window.location);
  const loc = window.location;



  return (
    <div style={{width: '100%'}}>
      <h1>
        The Lab
      </h1>
      <p style={{width:'100%'}}>The Lab is a web interface developed alongside the Isms collection that enables you to create grids, manipulate colors & gradients, add dynamic effects, and export creations.</p>
      <Button href={`${loc.protocol}//lab.${loc.host}`}>Enter the Lab</Button>
      <p>Try out some of the lab tools below.</p>
      <Demo />
    </div>
  );
}
