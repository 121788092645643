import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  itemPreviewRoot: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },

}));

export default function PreviewTile(props) {
  const classes = useStyles();

  return (
    <>
      {
        Object.keys(props.item).length === 0 ? (
          <div
            className={classes.itemPreviewRoot}
            style={{
              width: props.length,
              height: props.length,
              border: !props.backgroundColor ? '1px solid #d3d3d3' : '',
            }}
          >
          </div>
        ) : (
          <div
            className={classes.itemPreviewRoot}
            style={{
              width: props.length,
              height: props.length,
              paddingLeft: props.length * 0.05,
              paddingRight: props.length * 0.05,
              fontSize: props.length * (126/1000),
              fontWeight: props.item.weight,
              lineHeight: `${props.length * (132/1000)}px`,
              fontFamily: props.item.font,
              backgroundColor: props.invert ? props.item.textColor : props.item.backgroundColor,
              color: props.invert ? props.item.backgroundColor : props.item.textColor,
              border: !props.backgroundColor ? '1px solid #d3d3d3' : '',
            }}
          >
            {
              props.item.phrase
            }
          </div>
        )
      }
    </>

  );
}
