import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';



export default function Info(props) {

  const loc = window.location;


  return (
    <Box sx={{
      width: '100%',
      maxWidth: 900,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: 2,
      mb: '20px',
    }}>
      <h2 style={{width:'100%',textAlign:'center'}}>Combine & Customize</h2>
      <p style={{width:'100%',textAlign:'center'}}>The Lab is a web interface developed alongside the Isms collection that enables you to create grids, manipulate colors & gradients, add dynamic effects, and export creations.</p>
      <Box 
        sx={{
          display: 'flex',
          p: 2,
        }}
      >
        <Button 
          variant='outlined' 
          color='primary' 
          sx={{width:'200px',mx:2}}
          href='/lab'
        >
          Learn More
        </Button>
        <Button 
          variant='outlined' 
          color='primary' 
          sx={{width:'200px',mx:2}}
          href={`${loc.protocol}//lab.${loc.host}`}
        >
          Enter The Lab
        </Button>

      </Box>
    </Box>
  );
}
