import React from 'react';

export default function ColorPicker(props) {

  const updateDisplayColors = (color) => {
    let newDC = {...props.displayColors};
    if(!newDC.dynamic){
      newDC.colors = [color];
    } else if(newDC.colors.includes(color) && newDC.colors.length > 1) {
      newDC.colors = newDC.colors.filter(col => col != color);
    } else if(newDC.colors.includes(color) && newDC.colors.length == 1) {
      return true;
    } else if (newDC.colors.length == 3 && !newDC.colors.includes(color)) {
      return true;
    } else {
      newDC.colors = [...newDC.colors, color];
    }
    props.updateGridState('colors', newDC);
  };

  const swatches = [
    {
      name: 'Basics',
      colors: ['#000000','#202020','#303030','#404040','#606060','#808080','#a0a0a0','#c0c0c0','#e0e0e0','#ffffff'],
    },
    {
      name: 'Pastels',
      colors: ["#fbf8cc","#fde4cf","#ffcfd2","#f1c0e8","#cfbaf0","#a3c4f3","#90dbf4","#8eecf5","#98f5e1","#b9fbc0"],
    },
    {
      name: 'Slates',
      colors: ["#9da5ac","#adb5bd","#bbbfc4","#c8c9ca","#d5d3d0","#e2ddd6","#e8e5e0","#edede9","#e3d5ca","#d5bdaf"],
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {
        swatches.map((swatch, si) => (
          <div
            key={swatch.name}
            style={{ width: '100%' }}
          >
            <div
              style={{
                color: '#d3d3d3',
                marginBottom: 5,
              }}
            >
              { swatch.name }
            </div>
            <div
              style={{
                width: '100%',
                height: (props.width - 30) / 10 + 10,
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'no-wrap',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              {
                swatch.colors.map((color, ci) => (
                  <div
                    key={`${swatch.name}-${ci}`}
                    style={{backgroundColor: color}}
                    onClick={() => updateDisplayColors(color)}
                    style={{
                      backgroundColor: color,
                      width: props.displayColors.colors.includes(color) ? (props.width - 10) / 10 + 10 : (props.width - 10) / 10,
                      height: props.displayColors.colors.includes(color) ? (props.width - 10) / 10 + 10 : (props.width - 10) / 10,
                      border: props.displayColors.colors.includes(color) ? '1px solid #ADD8E6' : '',
                    }}
                  >
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  );
}
