import React from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function GalleryNav(props) {

  let filter = props.filter;
  let updateFilter = props.updateFilter;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'flex-end',
      }}
    >
      <FormControl  variant="standard" sx={{ m: 1, minWidth: 300 }}>
        <Select
          id="ism-gallery-select"
          value={filter.set}
          label="Ism"
          onChange={(e, newValue) => updateFilter('set',e.target.value)}
          style={{
            fontFamily: props.isms.find(ism => ism.iid == filter.set).font,
            color: '#545e6f',
            fontSize: 32,
            height: 65,
          }}
        >
          {
            props.isms.map((ism, iid) => (
              <MenuItem
                key={`ism-option-${iid}`}
                value={ism.iid}
                style={{fontFamily: ism.font, color: '#545e6f',fontSize: 24,}}
              >
                {ism.name}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl  variant="standard" sx={{ m: 1, width: 300 }}>
        <ToggleButtonGroup
          value={filter.palette}
          exclusive
          onChange={(e, newValue) => updateFilter('palette',e.target.value)}
          aria-label="palette select"
          fullWidth
          style={{ height: 50 }}
        >
          <ToggleButton value='pastel' aria-label="pastels">
            pastels
          </ToggleButton>
          <ToggleButton value='slate' aria-label="slates">
            slates
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
      <FormControl  variant="standard" sx={{ m: 1, width: 300 }}>
        <TextField
          id="search-bar"
          label="Search by ID"
          variant="standard"
          onChange={(e) => props.setSearch(e.target.value)}
          inputProps={{style: {fontSize: 28, fontFamily: 'Spartan'}}}
          InputLabelProps={{style: {fontSize: 28, fontFamily: 'Spartan'}}}
          style={{ height: 65, }}
          autoComplete='off'
          type='text'
        />
      </FormControl>
    </Box>
  );
}
