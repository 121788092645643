import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


export default function AnimationOptions(props) {

  const [type, setType] = useState(props.values.type);
  const [duration, setDuration] = useState(props.values.duration);
  const [shadow, setShadow] = useState(props.values.shadow);


  const handleAnimateChange = (type, stateFxn, e) => {
    stateFxn(e.target.value);
    let newAnimationObj = {...props.values};
    newAnimationObj[type] = e.target.value;
    props.updateValue('animationObj', newAnimationObj);
  };

  const timeOptions = ['1s','2s','3s','4s','5s','10s','15s','30s','60s'];

  return (
    <Box 
      sx={{ 
        width: '50%',
        minWidth: 160,
        flexGrow: 1, 
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <FormControl variant="standard" sx={{ mx: 1, width: '100%'}}>
          <InputLabel id="animate-type-label">Type</InputLabel>
          <Select
            labelId="animate-type-label"
            id="animate-type-select"
            name='type'
            value={type}
            onChange={(e) => handleAnimateChange('type', setType, e)}
            label="Type"
          >
            <MenuItem key='continuous' value={1}>Continuous</MenuItem>
            <MenuItem key='pulse' value={2}>Pulse</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ mx: 1, width: '100%'}}>
          <InputLabel id="animate-duration-label">Duration</InputLabel>
          <Select
            labelId="animate-duration-label"
            id="animate-duration-select"
            name='duration'
            value={duration}
            onChange={(e) => handleAnimateChange('duration', setDuration, e)}
            label="Duration"
          >
            {
              timeOptions.map(time => (
                <MenuItem key={time} value={time}>{time}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ mx: 1, width: '100%'}}>
          <InputLabel id="animate-shadow-label">Shadow</InputLabel>
          <Select
            labelId="animate-shadow-label"
            id="animate-shadow-select"
            name='shadow'
            value={shadow}
            onChange={(e) => handleAnimateChange('shadow', setShadow, e)}
            label="Shadow"
          >
            <MenuItem key='continuous' value={false}>Off</MenuItem>
            <MenuItem key='pulse' value={true}>On</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
