import React from 'react';

import DisplayBackground from './DisplayBackground';
import DisplayGrid from './grid/DisplayGrid';


export default function Display(props) {

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
      }}
    >
      <DisplayBackground displayColors={props.gridState.colors} >
        <DisplayGrid
          rows={props.gridState.rows}
          cols={props.gridState.cols}
          margin={props.gridState.margin}
          tileMargin={props.gridState.tileMargin}
          height={props.height}
          width={props.settings ? props.width/2 : props.width}
          items={props.items}
          intervalMap={props.intervalMap}
          itemStates={props.itemStates}
          setItemStates={props.setItemStates}
        />
      </DisplayBackground>
    </div>
  );
}
