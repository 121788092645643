import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import { css, keyframes } from "@emotion/react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function isOdd(num) { return num % 2;}

export default function WordCarousel(props) {
  const boxWidth = props.width ? props.width : 140;

  const [centerer, setCenterer] = useState(isOdd(props.items.length) ? props.items.length*boxWidth/2 : (props.items.length*boxWidth/2)+(boxWidth/2));
  const [location, setLocation] = useState({
    xoffset: -centerer,
    move: true,
  });

  const [items, setItems] = useState([...props.items]);

  useEffect(() => {
    setItems([...props.items]);
    setCenterer(isOdd(props.items.length) ? props.items.length*boxWidth/2 : (props.items.length*boxWidth/2)+(boxWidth/2));
    setLocation({
      xoffset: -centerer,
      move: true,
    });
  }, [props.items]);



  const handleNext = () => {
    setLocation(location => ({
      ...location,
      xoffset:location.xoffset-boxWidth,
      move: true,
    }));
    setTimeout(() => {

      let newItems = [...items];
      newItems.push(newItems.shift());
      setItems(newItems);
      setLocation(location => ({
        ...location,
        xoffset:-centerer,
        move: false,
      }));
    }, "330");

  }

  const handleBack = () => {

    setLocation(location => ({
      ...location,
      xoffset:location.xoffset+boxWidth,
      move: true,
    }));
    setTimeout(() => {
      let newItems = [...items];
      newItems.unshift(newItems.at(-1));
      newItems.pop();
      setItems(newItems);
      setLocation(location => ({
        ...location,
        xoffset:-centerer,
        move: false,
      }));
    }, "330");
  }


  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <IconButton aria-label="left" onClick={handleBack} sx={{mr:2}}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          height: '40px',
          overflowX: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0px 0px 10px 10px rgba(0,0,0,.1)',
        }}
      >
        <Box
          sx={{
            width: 0,
            position: 'relative',
            overflowX: 'visible',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: `${props.items.length*boxWidth}px`,
              display: 'flex',
              alignItems: 'center',
            }}
            style={{
              position: 'absolute',
              left: `${location.xoffset}px`,
              transition: location.move ? 'left 330ms ease-in-out' : '',
            }}
          >
            {
            items.map((i, ii) => (
              <Box key={i.text} sx={{width: '100%'}}>
                <Typography
                  variant='body1'
                  sx={{
                    flexGrow: 1,
                    width: `${boxWidth - 40}px`,
                    textAlign: 'center',
                    marginLeft: '20px',
                    marginRight: '20px',
                    fontFamily: i.font,
                  }}
                >
                  {i.text}
                </Typography>
              </Box>
              
            ))
          }

          </Box>
          
        </Box>
      </Box>

      <IconButton aria-label="right" onClick={handleNext} sx={{ml:2}}>
        <ArrowForwardIosIcon />
      </IconButton>

    </Box>

  );
}
