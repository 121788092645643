import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';




export default function Mint(props) {

  let navigate = useNavigate();
  function handleClick() {
    navigate("/");
  }

  useEffect(() => {

  }, []);



  return (
    <div style={{width: '100%'}}>
      <Typography
        variant='h2'
        color='primary'
      >
        Minting
      </Typography>
      <p>Release date TBD.</p>
    </div>
  );
}
