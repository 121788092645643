import React from 'react';
import { useNavigate } from "react-router-dom";


import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';


export default function InfoFab(props) {

  const navigate = useNavigate();

  const handleDirect = () => {
    if (props.info) {
      navigate('/');
    } else {
      navigate('/info');
    }
    props.setInfo(!props.info);
  }

  return (
    <Tooltip title="Info" placement={props.rows >= props.cols ? "left" : 'top'}>
      <IconButton
        aria-label='info'
        sx={{
          position: 'absolute',
          bottom: 'calc(50% - 20px)',
          right: props.rows >= props.cols ? 16 : 60,
          bottom: props.rows >= props.cols ? 60 : 16,
          color: props.info ? '#ADD8E6' : '#d3d3d3',
        }}
        color='inherit'
        onClick={handleDirect}
      >
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
}
