import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Item from '../../../../lab/controller/display/grid/Item';
import { intervalArr, itemIntervalObj } from '../../../../../static/lab/objects';


export default function TileBlock(props) {



  return (
    <Box
      sx={{
        flexGrow: 1,
        width: '50%',
      }}
    >
      <Item 
        item={props.item} 
        length={400} 
        margin={10} 
        t={props.itemState.t}
        bg={props.itemState.bg}
        invert={props.itemState.invert}
        toggle={props.itemState.toggle}
      />

    </Box>
  );
}
