import React from 'react';

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

const CollectionPhrase = styled('div', { shouldForwardProp: (prop) => prop !== 'width' })(
  ({ theme }) => ({
    display: 'flex',
  }),
);
const Phrase = styled('p', { shouldForwardProp: (prop) => prop !== 'width' })(
  ({ theme }) => ({
    padding: '15px 0px',
    margin: '5px 0px',
    color: '#545e6f',
    fontFamily: 'Spartan',
    fontSize: 24,
    "&:hover": {
      transform: 'scale(1.1)',
      transition: 'all 0.2s',
      cursor: 'pointer',
    },
  }),
);


export default function Collection(props) {

    return (
      <Box
        sx={{
          flexGrow: 1,
          height: '100%',
          px: 5,
          display: 'flex',
          flexDirection: 'column',
          borderLeft: '1px solid #d4d4d4',
          overflowY: 'auto',
        }}
      >
        {
          props.collection.map((phrase, pi) => (
            <>
              <CollectionPhrase>
                <Phrase>
                  { phrase }
                </Phrase>
              </CollectionPhrase>
              {
                pi < props.collection.length - 2 && (
                  <Divider />
                )
              }
            </>
          ))
        }
      </Box>
    );
  }
