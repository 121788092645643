import React from 'react';

import Box from '@mui/material/Box';

import ToggleGroup from '../../../layout/inputs/ToggleGroup';
import SliderItem from '../../../layout/inputs/SliderItem';


export default function LayoutSettings(props) {

  const gridOptions = [
    {
      name: '1',
      value: 1,
    },
    {
      name: '2',
      value: 2,
    },
    {
      name: '3',
      value: 3,
    },
    {
      name: '4',
      value: 4,
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: props.width
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'no-wrap',
        }}
      >
        <Box
          sx={{
            padding: 4,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ToggleGroup
            label='Rows'
            options={gridOptions}
            type='rows'
            updateValue={props.updateGridState}
            value={props.rows}
          />
          <ToggleGroup
            label='Columns'
            options={gridOptions}
            type='cols'
            updateValue={props.updateGridState}
            value={props.cols}
          />
        </Box>
        <Box
          sx={{
            padding: 4,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <SliderItem
            label='Display margin'
            type='margin'
            updateValue={props.updateGridState}
            value={props.margin}
            min={0}
            max={40}
            step={4}
          />
          <SliderItem
            label='Tile margin'
            type='tileMargin'
            updateValue={props.updateGridState}
            value={props.tileMargin}
            min={0}
            max={10}
            step={1}
          />
        </Box>
      </Box>
    </Box>

  );
}
