import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#545e6f',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: '#d4d4d4',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    error: {
      main: '#E74C3C',
    },
    warning: {
      main: '#E74C3C',
    },
    info: {
      light: '#0092d633',
      main: '#0092d6',
    },
    success: {
      main: '#18BC9C',
    },
    // error: will use the default color
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    // contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },
  // typography: {
  //   fontFamily: 'Trade Gothic',
  // },
  overrides: {
    // Style sheet name ⚛️
    MuiSvgIcon: {
      // Name of the rule
      colorAction: {
        // Some CSS
        color: '#89cff0',
      },
      fontSizeLarge: {
        fontSize: '44px'
      },
    },

  },
});

//
// MuiTypography: {
//   h1: {
//     fontSize: '64px',
//     marginBottom: '10px',
//     lineHeight: 1
//   },
//   h2: {
//     fontSize: '44px',
//     marginBottom: '10px',
//     lineHeight: 1
//   },
//   h3: {
//     fontSize: '36px',
//     marginBottom: '8px',
//     lineHeight: 1
//   },
//   h4: {
//     fontSize: '28px',
//     marginBottom: '8px',
//     lineHeight: 1
//   },
//   h5: {
//     fontSize: '20px',
//     marginBottom: '6px',
//     lineHeight: 1
//   },
//   h6: {
//     fontSize: '16px',
//     marginBottom: '6px',
//     lineHeight: 1
//   }
// },

export default theme;
