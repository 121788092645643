import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import Box from '@mui/material/Box';


export default function Doc(props) {

  const [file, setFile] = useState([]);

  useEffect(() => {

    fetch(props.doc.doc).then((response) => response.text()).then((text) => {
      setFile(text);
    });
  }, [props.doc]);


  return (
    <Box sx={{ }} >
      <ReactMarkdown>
        { file }
      </ReactMarkdown>
    </Box>
  );
}
