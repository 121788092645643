import React from 'react';

import Divider from '@mui/material/Divider';

export default function SettingsSection(props) {
  return (
    <div
      style={{
        width: props.width-20,
        margin: 10,
        flexGrow: 1,
      }}
    >
      <Divider sx={{marginBottom: 2}} textAlign="center">{ props.title }</Divider>
      <div>
        { props.children }
      </div>
    </div>
  );
}
