import React from 'react';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export default function SliderItem(props) {

  return (
    <Box sx={{ marginBottom: `20px` }} >
      <Box 
        sx={{
          color: '#d3d3d3',
          marginBottom: `5px`,
        }}
      >
        { props.label }
      </Box>
      <Slider
        aria-label={ props.label }
        defaultValue={props.value}
        value={props.value}
        onChange={(e, newVal) => {
          props.setValue && props.setValue(newVal);
          props.updateValue && props.updateValue(props.type, newVal);
        }}
        valueLabelDisplay="auto"
        step={props.step}
        marks
        min={props.min}
        max={props.max}
      />
    </Box>
  );
}
