import React from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  ismInfoRoot: {
    width: '100%',
    maxWidth: 600,
    padding: 40,
  },
  ismInfoRow: {
    width: '100%',
    padding: '10px 0px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  ismInfoHeader: {
    width: '30%',
    minWidth: 150,
  },
  ismInfoValue: {
    width: '70%',
    minWidth: 350,
    textAlign: 'right',
  },

}));

export default function IsmInfo(props) {
  const classes = useStyles();
  let navigate = useNavigate();
  function handleClick() {
    navigate("/gallery");
  }

  return (
    <div className={classes.ismInfoRoot}>
      <div className={classes.ismInfoRow}>
        <div className={classes.ismInfoHeader}>
          Collection
        </div>
        <div className={classes.ismInfoValue}>
          { props.data.set }
        </div>
      </div>
      <div className={classes.ismInfoRow}>
        <div className={classes.ismInfoHeader}>
          Phrase
        </div>
        <div className={classes.ismInfoValue}>
          { props.data.phrase }
        </div>
      </div>
      <div className={classes.ismInfoRow}>
        <div className={classes.ismInfoHeader}>
          Font
        </div>
        <div className={classes.ismInfoValue}>
          { props.data.font }
        </div>
      </div>
      <div className={classes.ismInfoRow}>
        <div className={classes.ismInfoHeader}>
          Text color
        </div>
        <div className={classes.ismInfoValue}>
          { props.data.textColor }
        </div>
      </div>
      <div className={classes.ismInfoRow}>
        <div className={classes.ismInfoHeader}>
          Background color
        </div>
        <div className={classes.ismInfoValue}>
          { props.data.backgroundColor }
        </div>
      </div>
    </div>

  );
}
