import React from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tileRoot: {
    width: '400px',
    height: '400px',
    margin: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },

}));

export default function IsmTile(props) {
  const classes = useStyles();
  let navigate = useNavigate();
  function handleClick() {
    navigate("/gallery");
  }

  return (
    <div
      type="button"
      onClick={handleClick}
      className={classes.tileRoot}
      style={{
        backgroundColor: props.data.backgroundColor,
        color: props.data.textColor,
        fontFamily: props.data.font,
        fontSize: props.width * (126/1000),
        lineHeight: `${props.width * (132/1000)}px`,
        paddingLeft: props.width * 0.05,
        paddingRight: props.width * 0.05,
      }}
    >
      { props.data.phrase }
    </div>

  );
}
