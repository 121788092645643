import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { NavLink } from "react-router-dom";

import MobileNav from './MobileNav';


export default function Nav(props) {

  let color = '#d4d4d4';
  // let color = colors[Math.floor(Math.random() * colors.length)];

  if(props.mobile){
    return (
      <MobileNav />
    )
  }

  return (
    <Box
      sx={{
        width: '100%',
        padding: '20px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Typography
        variant='h4'
        color='primary'
        style={({ isActive }) => ({
          color: isActive ? '#545e6f' : color,
          fontFamily: 'Spartan',
          textDecoration: 'none',
          width: 180,
          textAlign: 'center',
        })}
        component={NavLink}
        to='/mint'
      >
        mint
      </Typography>
      <Typography
        variant='h4'
        color='primary'
        style={({ isActive }) => ({
          color: isActive ? '#545e6f' : color,
          fontFamily: 'Spartan',
          textDecoration: 'none',
          width: 180,
          textAlign: 'center',
        })}
        component={NavLink}
        to='/about'
      >
        about
      </Typography>
      <Box
      
      >
        <Typography
          variant='h1'
          color='primary'
          style={({ isActive }) => ({
            color: isActive ? '#545e6f' : color,
            fontFamily: 'Spartan',
            textDecoration: 'none',
            width: 210,
            textAlign: 'center',
          })}
          component={NavLink}
          to='/'
        >
          isms
        </Typography>
        <Typography
          variant='body1'
          style={{
            color: color,
            fontFamily: 'Spartan',
            textDecoration: 'none',
            width: 210,
            textAlign: 'center',
          }}
        >
          An interactive NFT collection.
        </Typography>
      </Box>
      
      <Typography
        variant='h4'
        color='primary'
        component={NavLink}
        to='/gallery'
        style={({ isActive }) => ({
          color: isActive ? '#545e6f' : color,
          fontFamily: 'Spartan',
          textDecoration: 'none',
          width: 180,
          textAlign: 'center',
        })}
      >
        gallery
      </Typography>
      <Typography
        variant='h4'
        color='primary'
        component={NavLink}
        to='/lab'
        style={({ isActive }) => ({
          color: isActive ? '#545e6f' : color,
          fontFamily: 'Spartan',
          textDecoration: 'none',
          width: 180,
          textAlign: 'center',
        })}
      >
        lab
      </Typography>

    </Box>
  );
}
